export default [
  {
    value: 'Pending Assignment',
    title: 'Pending Assignment',
    color: 'error'
  },
  { value: 'In Progress', title: 'In Progress', color: 'blue' },
  { value: 'Assigned', title: 'Assigned', color: 'secondary' },
  { value: 'Scheduled', title: 'Scheduled', color: 'primary' },
  { value: 'Waiting', title: 'Waiting on parts', color: 'warning' },
  { value: 'Monitoring', title: 'Monitoring', color: 'orange' },
  {
    value: 'Pending Review',
    title: 'Pending Review',
    color: 'success darken-2'
  },
  { value: 'Complete', title: 'Complete', color: 'success' }
] as { value: App.Enums.Task.Status; title: string; color: string }[]
