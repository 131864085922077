import { useStoreStore } from '@/stores/store'
import { type App } from 'vue'

export declare interface StorePrototype {
  activeStore: App.Models.Store | null
  stores: App.Models.Store[]
  isStore: boolean
  isCorporate: boolean
}

export default {
  install(app: App) {
    const $store: StorePrototype = {
      get activeStore() {
        return useStoreStore().activeStore
      },
      get stores() {
        return useStoreStore().stores
      },
      get isStore() {
        return !!this.activeStore
      },
      get isCorporate() {
        return !this.isStore
      }
    }
    app.config.globalProperties.$store = $store
  }
}

