import { isEqual, transform, isObject, size, cloneDeep } from 'lodash'
import type { Dictionary } from 'vue-router/types/router'
import { defineComponent } from 'vue'

export const createChangesMixin = (changed_field: string) => {
  return defineComponent({
    data() {
      return {
        original: {} as Dictionary<any>
      }
    },
    computed: {
      changed_count() {
        // @ts-expect-error
        return size(this.calculateChanges(this[changed_field], this.original))
      },
      changes() {
        // @ts-expect-error
        return this.calculateChanges(this[changed_field], this.original)
      }
    },
    mounted() {
      // @ts-expect-error
      if (this[changed_field]) {
        // @ts-expect-error
        this.original = cloneDeep(this[changed_field])
      }
    },
    methods: {
      calculateChanges(current: any, original: any) {
        return transform(current, (result, value, key) => {
          if (!isEqual(value, original[key])) {
            // @ts-expect-error
            result[key] = isObject(value) && isObject(original[key]) ? this.calculateChanges(value, original[key]) : {
              key,
              current: value,
              original: original[key]
            }
          }
        })
      },
      setOriginal(original: any) {
        this.original = cloneDeep(original)
      },
      resetOriginal() {
        // @ts-expect-error
        this.original = cloneDeep(this[changed_field])
      },
      resetChanges() {
        // @ts-expect-error
        this[changed_field] = cloneDeep(this.original)
      }
    }
  })
}

export default createChangesMixin
