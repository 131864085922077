<template>
  <v-form
    ref="taskForm"
  >
    <h3 class="card-title mt-0 mb-1">
      Basic Information
    </h3>
    <v-select
      v-model="task.type"
      :items="types"
      label="Type*"
      :rules="rules.required"
    />
    <v-text-field
      v-model="task.name"
      label="Task Title*"
      :rules="rules.required"
    />
    <v-textarea
      v-model="task.description"
      label="Description"
    />
    <!--   Associated Store -->
    <app-auto-complete
      v-model="task.store_id"
      endpoint="/stores"
      v-if="!storeId"
      label="Store*"
      item-value="id"
      item-title="name"
      :items="$store.stores"
      no-data-text="Loading..."
      placeholder="Select Store"
      :rules="rules.required"
    />
    <!--   task Assigned To -->
    <app-auto-complete
      v-model="task.assigned_by_id"
      endpoint="/users"
      :filters="{storeId: task.store_id}"
      item-title="full_name"
      label="Assigned To"
      clearable
      hint="Select a store to assign task"
      :persistent-hint="!task.store_id"
      :disabled="!task.store_id"
    />

    <!-- task Image -->
    <h4 class="font-weight-bold">
      Files
    </h4>
    <table class="w-100">
      <task-fields-files
        v-model="task"
        class="mb-3"
      />
    </table>

    <h3 class="card-title mb-1">
      Planning
    </h3>
    <v-date-input
      :prepend-icon="null"
      label="Due Date"
      hide-actions
      v-model="task.due_date"
    />
    <v-date-input
      :prepend-icon="null"
      label="Reminder"
      hide-actions
      v-model="task.reminder"
    />
    <v-select
      v-model="task.priority"
      :items="priorities"
      item-color="color"
      label="Priority"
    />

    <template v-if="task.store_id">
      <h3 class="card-title mb-5">
        Associations
      </h3>
      <!--   Associated Equipment -->
      <equipment-ac
        v-model="task.equipment_id"
        :disabled="!task.store_id"
        label="Equipment"
      />
      <!--   Associated Incident Form Submission -->
      <app-auto-complete
        v-model="task.incident_form_submission_id"
        item-value="id"
        :item-title="(i) => `${i.data.first_name} ${i.data.last_name}`"
        endpoint="/incident-form-submissions"
        label="Incident Form Submission"
      />
    </template>
  </v-form>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash'
import validators from '@/mixins/validators'
import { createChangesMixin } from '@/mixins/changes'
import EquipmentAc from '@/components/EquipmentAc.vue'
import TaskFieldsFiles from '@/components/TaskFieldsFiles.vue'
import { defineComponent } from 'vue'
import taskPriorities from '@/enums/taskPriorities'
import taskTypes from '@/enums/taskTypes'
import { useGoTo } from 'vuetify'
import AppAutoComplete from '@/components/AppAutoComplete.vue'

const defaultTask = {
  type: 'repair',
  name: null,
  store_id: null,
  priority: 0,
  files: []
}
export default defineComponent({
  setup() {
    const goTo = useGoTo()
    return {
      goTo
    }
  },
  components: {
    AppAutoComplete,
    TaskFieldsFiles,
    EquipmentAc
  },
  mixins: [validators, createChangesMixin('task')],
  props: {
    equipmentId: {
      type: Number,
      default: null
    },
    findableId: {
      type: Number,
      default: undefined
    },
    findableType: {
      type: String,
      default: undefined
    },
    storeId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      task: cloneDeep(defaultTask) as App.Utils.NewModel<App.Models.Task>,
      types: taskTypes,
      priorities: taskPriorities
    }
  },
  mounted() {
    this.task.store_id = this.storeId
    this.task.equipment_id = this.equipmentId
  },
  methods: {
    async save(): Promise<false | App.Models.Task> {
      if (!(this.$refs.taskForm as Vuetify.VForm).validate()) {
        await this.$nextTick()
        await this.goTo(this.$el.querySelector('.v-messages.error--text:first-of-type'), { offset: 56 })
        return false
      }
      const { data: { task, message, errors }, status } = await this.$http.post('/tasks', {
        ...this.task,
        findable_id: this.findableId,
        findable_type: this.findableType
      })
        .catch((e) => e)
      if (this.$error(status, message, errors)) {
        return false
      }
      this.task = cloneDeep(defaultTask)
      return task
    }
  }
})
</script>

<style scoped lang="scss">

</style>
