import { $auth } from '@/plugins/auth'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { ROUTES } from '@/router/paths'
import routeTo from '@/plugins/routeTo'

export default class Authorization implements App.Middleware.Instance {
  async handle(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    if (!$auth.user) {
      return cancel(routeTo(ROUTES.LOGIN))
    }

    if (to.meta?.permissions_all?.length && !$auth.hasAllPermissions(to.meta.permissions_all)) {
      return cancel(routeTo(ROUTES.DASHBOARD))
    }

    if (to.meta?.permissions_any?.length && !$auth.hasAnyPermissions(to.meta.permissions_any)) {
      return cancel(routeTo(ROUTES.DASHBOARD))
    }

    await next(to, from, cancel)
  }
}
