import type { LocationQueryRaw, RouteLocationRaw, RouteRecordNormalized } from 'vue-router'
import router from '@/router'
import { useStoreStore } from '@/stores/store'

type Name = string | undefined
type Params = { [p: string]: string | string[] | number | number[] | null }

let routes: undefined | RouteRecordNormalized[] = undefined
export default function routeTo(name: Name, params: Params = {}, query: LocationQueryRaw = {}): RouteLocationRaw {
  if (!name) {
    // eslint-disable-next-line no-console
    console.trace('Unable to find route a route for "undefined". Please provide a route name.')
    return { name: 'NotFound' }
  }
  if (!routes) {
    routes = router.getRoutes()
  }
  const route = routes.find(r => r.name === name)

  if (!route) {
    // eslint-disable-next-line no-console
    console.error(`Route ${name} not found`)
    return { name: 'NotFound' }
  }

  if (route.path.includes(':storeId') && params.storeId === undefined && (router.currentRoute.value.params.storeId || useStoreStore().stores.length === 1)) {
    params.storeId = router.currentRoute.value.params.storeId || useStoreStore().stores[0].id
  }

  try {
    router.resolve({ name, params, query })
  } catch (e) {
    // console.error(e, router.currentRoute.value)
    // console.error(`Invalid route for ${name}`, params, query)
    return { name: 'NotFound' }
  }

  return { name, params, query }
}
