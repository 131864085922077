<template>
  <v-app
    id="carwashai"
    v-if="$auth.user"
  >
    <app-messages />
    <app-layout-drawer v-if="$vuetify.display.mdAndUp" />
    <app-layout-bottom-drawer v-else />
    <app-layout-toolbar />
    <task-filter-drawer />
    <div id="outside-main" />
    <v-main>
      <slot />
    </v-main>

    <active-maintenance />
    <update-dialog />
  </v-app>
</template>

<script lang="ts">
import AppLayoutToolbar from '@/components/AppLayoutToolbar.vue'
import AppLayoutDrawer from '@/components/AppLayoutDrawer.vue'
import ActiveMaintenance from '@/components/ActiveMaintenance.vue'
import AppLayoutBottomDrawer from '@/components/AppLayoutBottomDrawer.vue'
import { defineComponent } from 'vue'
import UpdateDialog from '@/components/UpdateDialog.vue'
import TaskFilterDrawer from '@/components/TaskFilterDrawer.vue'
import AppMessages from '@/components/AppMessages.vue'

export default defineComponent({
  components: {
    TaskFilterDrawer,
    UpdateDialog,
    AppLayoutBottomDrawer,
    ActiveMaintenance,
    AppLayoutDrawer,
    AppLayoutToolbar,
    AppMessages
  },
  middleware: ['stores', 'preventCorporateForSingleStoreCompanies']
})
</script>

<style scoped></style>
