import moment from 'moment'
import { forEach } from 'lodash'
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router'

export default class ForceTypes implements App.Middleware.Instance {
  async handle(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    // @ts-ignore
    forEach(to.params, (param: string, key: string) => {
      if (param === null || param === undefined) {
        // @ts-ignore
        return (to.params[key] = undefined)
      }
      // @ts-ignore
      if (!isNaN(param)) {
        // @ts-ignore
        return (to.params[key] = Number(param))
      }
      if (moment(param).isValid()) {
        // @ts-ignore
        return (to.params[key] = moment(param))
      }
      if (param === 'true' || param === 'false') {
        // @ts-ignore
        return (to.params[key] = Boolean(param))
      }
    })

    await next(to, from, cancel)
  }
}
