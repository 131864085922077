import { defineStore } from 'pinia'
import axios from 'axios'
import errorHandler from '@/plugins/errorHandler'
import { $auth } from '@/plugins/auth'
import type { AxiosResponse } from '@/plugins/axios'

export const useStoreStore = defineStore('store', {
  state: () => {
    return {
      stores: [] as App.Models.Store[],
      stores_loaded: false,
      activeStore: null as null | App.Models.Store
    }
  },
  actions: {
    async loadStores() {
      const { data: { data, message }, status }: AxiosResponse<{
        data: App.Models.Store[]
      }> = await axios.get('/stores', {
        params: {
          itemsPerPage: -1
        }
      }).catch((e) => e)
      if (errorHandler(status, message)) {
        return
      }
      this.stores = data.sort((a, b) => a.name.localeCompare(b.name))
      this.stores_loaded = true
    },
    async setStore(storeId: string | null) {
      const {
        data: { user, message },
        status
      } = await axios.post(`/stores/set-store/${storeId}`).catch((e) => e)
      if (errorHandler(status, message)) {
        return
      }
      $auth.user = user
    }
  }
})
