<template>
  <v-navigation-drawer
    v-model="drawer"
    color="primary"
    :mobile-breakpoint="1025"
    :icon-variant="icon"
    width="290"
    style="z-index: 5"
  >
    <template #prepend>
      <v-list-item
        height="64px"
        nav
        :prepend-avatar="logo"
        @click.stop="$router.push($routeTo(ROUTES.DASHBOARD))"
      >
        <v-list-item-title>
          Car Wash Ai
        </v-list-item-title>
      </v-list-item>
      <v-divider class="m-1" />
      <app-layout-store-selector in-drawer />
      <v-divider class="m-1" />
    </template>
    <v-list
      min-height="100%"
      selected-class="secondary primary--text"
      nav
    >
      <template
        v-for="(link, i) in availableLinks"
        :key="i"
      >
        <v-list-group
          v-if="link.children"
        >
          <template #activator="{props}">
            <v-list-item
              v-bind="props"
            >
              <template #prepend>
                <v-icon :icon="link.icon" />
              </template>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <template
            v-for="(childLink, b) in link.children"
            :key="b"
          >
            <template v-if="childLink.title">
              <v-list-subheader
                class="text-white"
              >
                {{ childLink.text }}
              </v-list-subheader>
              <v-divider />
            </template>
            <v-list-item
              v-else
              :to="$routeTo(childLink.to!)"
              exact
            >
              <v-list-item-title>
                {{ childLink.text }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          v-else
          :to="$routeTo(link.to!, {storeId: link.corporate ? null : undefined})"
          active-class="secondary primary--text"
        >
          <template #prepend>
            <v-icon :icon="link.icon" />
          </template>

          <v-list-item-title v-text="link.text" />
        </v-list-item>
      </template>
    </v-list>
    <template #append>
      <v-list-item
        :to="$routeTo(ROUTES.MY_ACCOUNT)"
      >
        <template #prepend>
          <v-icon icon="mdi-account" />
        </template>
        <v-list-item-title> Manage Account</v-list-item-title>
      </v-list-item>
      <v-list-item
        href="https://carwashai-test.atlassian.net/servicedesk/customer/portals"
        target="_blank"
      >
        <template #prepend>
          <v-icon icon="mdi-face-agent" />
        </template>
        <v-list-item-title> Support</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <template #prepend>
          <v-icon icon="mdi-logout" />
        </template>
        <v-list-item-title> Log Out</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash'
import AppLayoutStoreSelector from '@/components/AppLayoutStoreSelector.vue'
import Logo from '@/images/logo-small.png'
import Avatar from '@/images/avatar.png'
import { useAppStore } from '@/stores/app'
import { mapWritableState } from 'pinia'
import { defineComponent } from 'vue'
import { ROUTES } from '@/router/paths'

interface RouteDefinition {
  to?: string;
  icon?: string;
  text: string;
  permissions?: string[];
  children?: (RouteDefinition & { title?: boolean })[];
  storeOnly?: boolean;
  corporate?: boolean;
  dashboardOnly?: boolean;
}

export default defineComponent({
  components: { AppLayoutStoreSelector },
  data: () => ({
    logo: Logo,
    default_profile: Avatar
  }),
  computed: {
    ...mapWritableState(useAppStore, ['drawer']),
    icon() {
      return !this.drawer
    },
    links(): RouteDefinition[] {
      const isStore = this.$store.activeStore || this.$store.stores.length === 1
      return [
        {
          to: ROUTES.DASHBOARD,
          icon: 'md:dashboard',
          text: (isStore ? 'Store' : 'Company') + ' Dashboard'
        },
        {
          to: ROUTES.TASK_CENTER,
          icon: 'md:build',
          text: 'To Do\'s',
          permissions: [
            'View Task',
            'Conduct Maintenance',
            'Assign Checklist',
            'Conduct Checklist'
          ],
          children: [
            {
              to: ROUTES.TASK_CENTER,
              icon: isStore ? 'TC' : 'md:build',
              text: isStore
                ? 'Task Center'
                : 'Company Task Center',
              permissions: ['View Task']
            },
            {
              to: ROUTES.MAINTENANCE_QUEUE,
              icon: 'SM',
              storeOnly: true,
              text: 'Maintenance Dashboard',
              permissions: ['Conduct Maintenance']
            },
            {
              to: ROUTES.CHECKLISTS,
              icon: 'CL',
              storeOnly: true,
              text: 'Checklists',
              permissions: [
                'Conduct Checklist',
                'Manage Checklist',
                'Assign Checklist'
              ]
            }
          ]
        },
        {
          icon: 'mdi-car',
          text: 'Incident Reports',
          permissions: [
            'Manage IncidentFormSubmission',
            'Submit IncidentFormSubmission',
            'Manage IncidentForm'
          ],
          children: [
            {
              to: ROUTES.INCIDENT_REPORT_DASHBOARD,
              icon: isStore ? 'ID' : 'mdi-car',
              text: isStore
                ? 'Dashboard'
                : 'Company Incident Reports',
              permissions: ['Manage IncidentFormSubmission']
            },
            {
              to: ROUTES.INCIDENT_REPORT_SUBMISSION,
              icon: 'DS',
              storeOnly: true,
              text: 'Submit Report',
              permissions: ['Submit IncidentFormSubmission']
            },
            {
              to: ROUTES.INCIDENT_REPORT_FORM,
              icon: 'EF',
              storeOnly: true,
              text: 'Edit Form',
              permissions: ['Manage IncidentForm']
            }
          ]
        },
        {
          to: ROUTES.INVENTORY,
          icon: 'md:inventory',
          text: 'Inventory'
        },
        {
          icon: 'mdi-file-chart',
          text: 'Reports',
          permissions: ['Manage Checklist', 'Assign Checklist'],
          children: [
            {
              title: true,
              text: 'Checklist'
            },
            {
              to: ROUTES.CHECKLIST_HISTORY,
              icon: 'SH',
              text: 'Submission History',
              permissions: ['Manage Checklist', 'Assign Checklist']
            },
            {
              to: ROUTES.CHECKLIST_ANSWER_HISTORY,
              icon: 'AH',
              text: 'Answer History',
              permissions: ['Manage Checklist', 'Assign Checklist']
            }
          ]
        },
        {
          to: ROUTES.STORE_SETTINGS,
          icon: 'mdi-store-cog',
          text: 'Store Settings',
          permissions: [
            'Manage Store',
            'Manage Location',
            'Manage User',
            'Manage Location',
            'Manage Supplier',
            'Manage Equipment',
            'Manage Maintenance',
            'Manage Checklist',
            'Manage Workflow'
          ],
          storeOnly: true,
          children: [
            {
              to: ROUTES.STORE_SETTINGS,
              icon: 'SS',
              text: 'Store Settings',
              permissions: ['Manage Store']
            },
            {
              to: ROUTES.STORE_SETTINGS_EQUIPMENT,
              icon: 'ME',
              text: 'Manage Equipment',
              permissions: ['Manage Equipment']
            },
            {
              to: ROUTES.STORE_SETTINGS_EQUIPMENT_GROUPS,
              icon: 'EG',
              text: 'Equipment Groups',
              permissions: ['Manage Equipment']
            },
            {
              to: ROUTES.STORE_SETTINGS_EQUIPMENT_ORDER,
              icon: 'EG',
              text: 'Equipment Order',
              permissions: ['Manage Equipment']
            },
            {
              to: ROUTES.STORE_SETTINGS_MAINTENANCE,
              icon: 'MC',
              text: 'Manage Maintenance',
              permissions: ['Manage Maintenance']
            },
            {
              to: ROUTES.STORE_SETTINGS_CHECKLISTS,
              icon: 'MC',
              text: 'Manage Checklists',
              permissions: ['Manage Checklist']
            },
            {
              to: ROUTES.STORE_SETTINGS_USERS,
              icon: 'MU',
              text: 'Manage Users',
              permissions: ['Manage User']
            },
            {
              to: ROUTES.STORE_SETTINGS_SUPPLIERS,
              icon: 'MS',
              text: 'Manage Suppliers',
              permissions: ['Manage Supplier']
            },
            {
              to: ROUTES.STORE_SETTINGS_WORKFLOW,
              icon: 'WF',
              text: 'Manage Workflows'
              // permissions: ["Manage Workflow"],
            }
          ]
        },
        {
          icon: 'mdi-cog',
          text: 'Company Settings',
          corporate: true,
          children: [
            {
              to: ROUTES.COMPANY_SETTINGS_GENERAL,
              icon: 'CS',
              text: 'Company Settings',
              permissions: ['Details Company']
            },
            {
              to: ROUTES.COMPANY_SETTINGS_ROLES,
              icon: 'CS',
              text: 'Manage Roles',
              permissions: ['Roles Company']
            },
            {
              to: ROUTES.COMPANY_SETTINGS_USERS,
              icon: 'CS',
              text: 'Manage Users',
              permissions: ['Users Company']
            },
            {
              to: ROUTES.COMPANY_SETTINGS_SUBSCRIPTIONS,
              icon: 'CS',
              text: 'Manage Stores',
              permissions: ['Subscriptions Company']
            }
          ],
          permissions: [
            'Details Company',
            'Users Company',
            'Subscriptions Company',
            'Roles Company'
          ]
        }
      ]
    },
    availableLinks() {
      let links = cloneDeep(this.links)
      links = links.filter((link) => this.canShow(link))
      links.forEach((link) => {
        if (link?.children?.length) {
          link.children = link.children.filter((link) => this.canShow(link))
        }
      })
      return links.map((link) => {
        if (link?.children?.length === 1) {
          return { ...link.children[0], icon: link.icon }
        }
        return link
      })
    }
  },
  methods: {
    canShow(route: RouteDefinition) {
      const isStore = this.$store.activeStore || this.$store.stores.length === 1
      let show = true

      // Hide store only routes when within dashboard
      if (route.storeOnly && !isStore) {
        show = false
      }

      // Hide dashboard only routes when within store
      if (route.dashboardOnly && isStore) {
        show = false
      }

      // Hide routes not for the current users role
      if (route.permissions && !this.$auth.hasAnyPermissions(route.permissions)) {
        show = false
      }

      return show
    },
    async logout() {
      await this.$auth.logout()
      await this.$router.push(this.$routeTo(this.ROUTES.LOGIN))
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
