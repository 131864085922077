import { defineStore } from 'pinia'
import errorHandler from '@/plugins/errorHandler'
import axios from 'axios'
import router from '@/router'
import routeTo from '@/plugins/routeTo'
import { ROUTES } from '@/router/paths'

interface State {
  session: App.Models.MaintenanceSession | null
  index: number
  showNavigation: boolean
  loading: boolean
}

export const useConductMaintenanceStore = defineStore('conductMaintenance', {
  state: (): State => {
    return {
      session: null,
      index: 0,
      showNavigation: true,
      loading: false
    }
  },
  getters: {
    activeMaintenance(state) {
      if (!state.session) {
        return null
      }
      return state.session.maintenances![state.index]
    }
  },
  actions: {
    async loadCurrentSession(): Promise<void> {
      this.loading = true
      const {
        data: { session, message, errors },
        status
      } = await axios.get('/maintenance-sessions', {}).catch((e) => e)
      this.loading = false
      if (errorHandler(status, message, errors) || !session) {
        await router.push(routeTo(ROUTES.MAINTENANCE_QUEUE))
        return
      }
      this.session = session
    },
    async complete(): Promise<void> {
      this.loading = true
      const {
        data: { message, errors },
        status
      } = await axios.post(`/maintenance-sessions/complete-maintenance-session/${this.session!.id}`).catch((e) => e)
      this.loading = false
      if (errorHandler(status, message, errors)) {
        return
      }
      await router.push(routeTo(ROUTES.MAINTENANCE_QUEUE))
    },
    async next(): Promise<void> {
      if (this.activeMaintenance!.pivot.complete === true) {
        this.index++
        return
      }
      this.activeMaintenance!.pivot.complete = true
      const maintenanceIds = [this.activeMaintenance!.id]
      this.loading = true
      const {
        data: { message, errors },
        status
      } = await axios.post('/maintenance-sessions/complete-maintenance/' + this.session!.id, {
        maintenanceIds
      })
        .catch((e) => e)
      this.loading = false
      if (errorHandler(status, message, errors)) {
        return
      }
      this.index += 1
    },
    previous(): void {
      this.index -= 1
    }
  }
})
