import { useStoreStore } from '@/stores/store'
import { $auth } from '@/plugins/auth'
import routeTo from '@/plugins/routeTo'
import { ROUTES } from '@/router/paths'
import type { NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router'

export default class ActiveStore implements App.Middleware.Instance {
  async handle(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    if (!$auth.user) {
      return next(to, from, cancel)
    }

    const storeStore = useStoreStore()
    if (!storeStore.stores_loaded) {
      await storeStore.loadStores()
    }

    storeStore.activeStore = storeStore.stores.find((s) => s.id === to.params.storeId) || null

    if (to.params.storeId) {
      if (!storeStore.stores.map(s => s.id).includes(to.params.storeId as string)) {
        return cancel(routeTo(ROUTES.DASHBOARD, { storeId: null }))
      }
    }

    return next(to, from, cancel)
  }
}
