<template>
  <div>
    <v-btn
      :loading="internalLoading || loading"
      v-bind="$attrs"
      @click="open"
    >
      <slot />
    </v-btn>
    <v-messages
      v-if="errorMessage.length > 0"
      v-model="errorMessage"
      class="mx-auto mb-1"
      color="error"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useFileDialog } from '@vueuse/core'
import useHttp from '@/composables/useHttp'

const props = withDefaults(defineProps<{
  accept?: string
  multiple?: boolean
  returnObject?: boolean
  maxSize?: number | null
  loading?: boolean
}>(), {
  accept: 'image/png, image/jpeg, image/jpg',
  maxSize: null
})

const errorMessage = ref<string[]>([])
const internalLoading = ref(false)

const { open, reset, onChange } = useFileDialog({
  accept: props.accept,
  multiple: props.multiple
})

onChange((files) => {
  if (!files || files.length === 0) {
    return
  }

  uploadFile(files)
})

const { $http, errorHandler } = useHttp()
const $emit = defineEmits(['change', 'files'])

async function uploadFile(files: FileList) {
  errorMessage.value = []
  const formData = new FormData()

  // Validate files for size restrictions
  if (props.maxSize) {
    for (const file of files) {
      if (file.size > props.maxSize) {
        const size = Math.floor(props.maxSize / 1000000)
        errorMessage.value.push(`File size must be less than ${size}mb`)
      }
    }
  }

  if (errorMessage.value.length > 0) {
    return
  }

  for (const file of files) {
    formData.append('files[]', file)
  }

  internalLoading.value = true
  const {
    data: { files: fileObjects, message, errors },
    status
  } = await $http.post('/files', formData).catch(e => e)
  internalLoading.value = false
  if (errorHandler(status, message, errors)) {
    errorMessage.value.push(message)
    return
  }

  if (props.returnObject) {
    $emit('change', props.multiple ? fileObjects : fileObjects[0])
    $emit('files', props.multiple ? fileObjects : fileObjects[0])
  } else {
    $emit('change', props.multiple ? fileObjects.map((file: App.Models.File) => file.id) : fileObjects[0].id)
    $emit('files', props.multiple ? fileObjects : fileObjects[0])
  }
  reset()
}
</script>

<style lang="scss" scoped>
</style>
