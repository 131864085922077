import type {
  ActionPerformed,
  // PushNotificationSchema,
  Token
} from '@capacitor/push-notifications'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import axios from 'axios'
import router from '@/router'
import { useAppStore } from '@/stores/app'

export default {
  requestPermissions() {
    if (!Capacitor.isNativePlatform()) return

    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
      } else {
        // Show some error
      }
    })
  }
}

if (Capacitor.isNativePlatform()) {
  PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value)
      axios.post('/auth/push-token', { push_token: token.value }).catch((e) => e)
    }
  )

  PushNotifications.addListener('registrationError', (error: any) => {
      useAppStore().addError('Error on registration: ' + JSON.stringify(error))
    }
  )

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', (payload: ActionPerformed) => {
      const url = payload.notification.data?.url
      if (!url) return

      const path = new URL(url).pathname
      router.push(path).catch((e) => e)
    }
  )
}
