import { defineStore } from 'pinia'
import { cloneDeep, merge } from 'lodash'

const defaultFilters: TaskFilters = {
  statuses: [],
  priority: -1,
  types: [],
  assigned_to_ids: [],
  assigned_by_ids: [],
  created_by_ids: [],
  equipment_ids: [],
  due_date: [null, null],
  completed_date: [null, null],
  sort_field: null,
  sort_direction: 'ASC',
  store_ids: [],
  search: null
}

interface State {
  pagination: App.Pagination
  filters: TaskFilters
  taskViewTab: 'details' | 'activity'
  showFilterSidebar: boolean
  priorities: { value: App.Enums.Task.PriorityFilter; text: string }[]
  showTaskFormDrawer: boolean
}

export const useTaskStore = defineStore('task', {
  state: (): State => {
    return {
      pagination: {
        sort: null,
        direction: 'DESC',
        page: 0,
        perPage: 10,
        count: 0
      },
      filters: cloneDeep(defaultFilters),
      taskViewTab: 'details',
      showFilterSidebar: false,
      priorities: [
        { value: -1, text: 'None' },
        { value: 1, text: 'Low' },
        { value: 2, text: 'Medium' },
        { value: 3, text: 'High' }
      ],
      showTaskFormDrawer: false
    }
  },
  actions: {
    clearFilters(): void {
      this.filters = cloneDeep(defaultFilters)
    },
    setFilters(filters: Partial<TaskFilters>): void {
      this.filters = merge(this.filters, filters) as TaskFilters
    }
  }
})


export interface TaskFilters {
  statuses: string[],
  priority: App.Enums.Task.PriorityFilter,
  types: string[],
  assigned_to_ids: string[],
  assigned_by_ids: string[],
  created_by_ids: string[],
  equipment_ids: number[],
  due_date: [Date | null, Date | null],
  completed_date: [Date | null, Date | null],
  sort_field: string | null,
  sort_direction: 'DESC' | 'ASC' | null,
  store_ids: [],
  search: string | null
}
