<template>
  <app-dialog
    ref="dialog"
    v-model="modal"
    v-model:return-value="internal_date"
    persistent
    :width="$vuetify.display.smAndDown ? undefined : '290px'"
    :disabled="disabled || readonly"
  >
    <template #activator="{ props }">
      <v-text-field
        v-model="formattedDate"
        :prepend-inner-icon="prependIcon"
        :label="label"
        :hide-details="hideDetails"
        :placeholder="placeholder"
        :required="required"
        :rules="rules"
        readonly
        v-bind="mergeProps($attrs, props)"
      />
    </template>
    <v-date-picker
      v-if="modal"
      v-model="internal_date"
    >
      <v-spacer />
      <v-btn
        variant="text"
        color="primary"
        @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
        variant="text"
        color="primary"
        @click="$refs.dialog.save(internal_date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </app-dialog>
</template>

<script lang="ts">
import moment from 'moment'
import { defineComponent, mergeProps } from 'vue'
import AppDialog from '@/components/AppDialog.vue'

// Create Base Mixins and Define Custom Properties
export default defineComponent({
  methods: { mergeProps },
  components: { AppDialog },
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Array],
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      modal: false,
      internal_date: null
    }
  },

  computed: {
    formattedDate() {
      if (!this.modelValue) {
        return this.modelValue
      }
      if (Array.isArray(this.modelValue)) {
        let formatted = ''
        if (this.modelValue[0]) {
          formatted += moment(this.modelValue[0]).format('MMM Do Y')
        }
        if (this.modelValue[1]) {
          formatted += ' - ' + moment(this.modelValue[1]).format('MMM Do Y')
        }
        return formatted
      }
      return moment(this.modelValue).format('MMM Do Y')
    }
  },
  watch: {
    internal_date(val) {
      if (val !== this.modelValue) {
        this.$emit('update:modelValue', val)
      }
    }
  }
})
</script>

<style scoped></style>
