<template>
  <v-app>
    <app-messages />
    <v-main class="login-layout">
      <v-container class="login-layout__container">
        <div class="login-layout__container__images">
          <v-img
            class="login-layout__container__images__image-right"
            src="@/images/login-right.png"
          />
          <v-img
            class="login-layout__container__images__image-left"
            src="@/images/login-left.png"
          />
          <slot />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppMessages from '@/components/AppMessages.vue'

export default defineComponent({
  components: {
    AppMessages
  }
})
</script>

<style lang="scss">
@use "sass:map";

.login-layout {
  --max-height: 700px;
  --max-width: 600px;
  @media #{map.get(settings.$display-breakpoints, 'sm-and-down')} {
    --max-height: 100%;
    --max-width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
    @media #{map.get(settings.$display-breakpoints, 'sm-and-down')} {
      padding: 0;
    }

    &__images {
      position: relative;
      height: 100%;
      max-height: var(--max-height);
      width: 100%;
      max-width: var(--max-width);

      &__image-right {
        position: absolute;
        right: -50%;
        top: 0;
        width: 597px;
        height: 397px;
        @media #{map.get(settings.$display-breakpoints, 'sm-and-down')} {
          display: none;
        }
      }

      &__image-left {
        position: absolute;
        left: -50%;
        bottom: -3px;
        width: 607px;
        height: 461px;
        @media #{map.get(settings.$display-breakpoints, 'sm-and-down')} {
          display: none;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: var(--max-width);
      height: 100%;
      max-height: var(--max-height);
      justify-content: space-between;
      align-items: stretch;
      padding: 32px 65px;
      background-color: rgb(var(--v-theme-primary));
      border-radius: 12px;
      position: relative;
      @media #{map.get(settings.$display-breakpoints, 'sm-and-down')} {
        border-radius: 0;
      }

      &__logo {
        text-align: center;
      }

      &__title {
        padding-top: 20px;
        padding-bottom: 20px;

        * {
          color: white;
          text-align: center;
          font-size: 48px;
          font-family: "DM Sans";
          font-weight: 700;
          line-height: 56px;
        }


        &__subtitle, a {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &__form {
        color: white;
      }
    }
  }
}
</style>
