<template>
  <v-navigation-drawer
    :width="400"
    :permanent="$vuetify.display.mdAndUp"
  >
    <template #prepend>
      <v-list-item height="54px">
        <v-list-item-title>Maintenance Queue</v-list-item-title>
      </v-list-item>
    </template>
    <v-list
      v-if="session"
      v-model:selected="selected"
      mandatory
      nav
    >
      <v-list-item
        v-for="(maintenance, i) in session?.maintenances"
        :key="maintenance.id"
        :value="i"
        :id="`maintenance-${i}`"
        @click="showNavigation = false"
        v-tooltip:top="maintenance.display_name"
      >
        <template #prepend>
          <v-icon>{{ maintenance.pivot.complete ? 'mdi-check-circle' : 'mdi-check-circle-outline' }}</v-icon>
        </template>
        <v-list-item-title>{{ maintenance.display_name }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :id="`maintenance-${session?.maintenances?.length}`"
        :value="session?.maintenances?.length"
        v-if="session?.maintenances?.length > 0"
      >
        <template #prepend>
          <v-icon>mdi-check-circle-outline</v-icon>
        </template>
        <v-list-item-title>Review</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapWritableState } from 'pinia'
import { useConductMaintenanceStore } from '@/stores/conductMaintenance'
import { useGoTo } from 'vuetify'

export default defineComponent({
  setup() {
    const goTo = useGoTo({
      container: '.v-navigation-drawer__content',
      duration: 300
    })

    return {
      goTo
    }
  },
  computed: {
    ...mapWritableState(useConductMaintenanceStore, [
      'index',
      'session',
      'showNavigation'
    ]),
    selected: {
      get() {
        return [this.index]
      },
      set(value: number[]) {
        this.index = value[0]
      }
    }
  },
  watch: {
    index() {
      this.goTo(`#maintenance-${this.index}`)
    }
  }
})
</script>

<style scoped></style>
