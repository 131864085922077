import { createApp } from 'vue'
import { createPinia } from 'pinia'
import vuetify from '@/plugins/vuetify'
import { loadLayouts } from '@/layouts'
import { usePlugins } from '@/plugins'
import App from '@/App.vue'
import '@/scss/main.scss'
import router from '@/router'

const app = createApp(App)

app.use(router)
app.use(vuetify)
usePlugins(app)
loadLayouts(app)
app.use(createPinia())


router.isReady().then(() => {
  app.mount('#app')

})

export default app
