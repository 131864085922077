<template>
  <svg
    id="animated-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="50%"
    viewBox="0 0 1646.616 520.624"
    fill="none"
    stroke="#ffc940"
  >
    <g
      id="Group_1"
      data-name="Group 1"
      transform="translate(-14.291 -244.106)"
    >
      <g
        id="i"
        data-name="Robot i"
        transform="translate(1489.484 247.47)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M99.943,9.034l-24.32,9.773a3.083,3.083,0,0,0-2.5-1.364H61.077a3.141,3.141,0,0,0-2.5,1.364L34.257,8.58a2.717,2.717,0,0,0-3.637,2.727V40.854a2.913,2.913,0,0,0,3.637,2.727l24.32-10.228a3.083,3.083,0,0,0,2.5,1.364H73.123a3.141,3.141,0,0,0,2.5-1.364l24.32,10.455a2.717,2.717,0,0,0,3.637-2.727V11.534C103.352,9.716,101.534,8.352,99.943,9.034Z"
          transform="translate(18.019 175.523)"
          stroke-width="4"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M31.772,12.811V89.634c-.227,2.5-.455,5.228-.455,7.955a79.892,79.892,0,0,0,159.556,6.137v-.682a45.66,45.66,0,0,0,.227-5.682c0-1.818-.227-3.637-.227-5.455V12.356A6.672,6.672,0,0,0,193.6,7.129a6.819,6.819,0,1,0-13.637,0,6.394,6.394,0,0,0,2.955,5.455v50A79.721,79.721,0,0,0,39.5,62.359V12.811a6.672,6.672,0,0,0,2.727-5.228,6.819,6.819,0,1,0-13.637,0A7.6,7.6,0,0,0,31.772,12.811Zm117.735,93.642a10.228,10.228,0,1,1-10.228,10.228A10.487,10.487,0,0,1,149.507,106.453Zm-77.5,0a10.228,10.228,0,1,1-10.228,10.228A10.332,10.332,0,0,1,72,106.453Z"
          transform="translate(-26.09 -0.31)"
          stroke-width="4"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M190.308,9.32H158.943V25.457a8.312,8.312,0,0,1-3.637,6.819,8.2,8.2,0,0,1-4.546,1.364,11.407,11.407,0,0,1-2.727-.455L125.759,25a17.016,17.016,0,0,0-4.773-.455H106.667a8.635,8.635,0,0,0-4.773.455L79.62,33.185a8.76,8.76,0,0,1-7.5-.909,8.312,8.312,0,0,1-3.637-6.819V9.32H36.662A8.148,8.148,0,0,0,28.48,17.5V310.248a9.6,9.6,0,0,0,9.546,9.546H188.263a9.6,9.6,0,0,0,9.546-9.546V17.5C198.491,12.957,194.854,9.32,190.308,9.32Z"
          transform="translate(-28.48 195.466)"
          stroke-width="4"
        />
      </g>
      <g id="a">
        <path
          id="Path_4"
          data-name="Path 4"
          d="M603.98.477,396.24.25C382.6.7,374.42,4.568,367.147,14.342L1.9,501.873A9.221,9.221,0,0,0,9.17,516.647H163.725A8.833,8.833,0,0,0,171,513.01l262.744-355.7c5.228-7.273,16.819-3.409,16.592,5.682l.682,173.42h-106.6a7.965,7.965,0,0,0-6.364,3.182L262.822,444.369a7.762,7.762,0,0,0,6.364,12.273H451.471l.227,44.321a15.471,15.471,0,0,0,15.456,15.456H602.389A19.3,19.3,0,0,0,621.709,497.1V18.888C621.709,9.114,613.754.477,603.98.477Z"
          transform="translate(843.001 245.856)"
          stroke-width="4"
        />
      </g>
      <g
        id="Car_Wash_Text"
        data-name="Car Wash Text"
        transform="translate(16.291 559.832)"
      >
        <path
          id="Path_6"
          data-name="Path 6"
          d="M197.605,203.943a92.492,92.492,0,0,1-16.079-1.4,82.466,82.466,0,0,1-15.2-4.188,99.071,99.071,0,0,1-13.8-6.411,68.874,68.874,0,0,1-8.582-5.739,3.585,3.585,0,0,1-.465-5.274l22.542-24.507a3.515,3.515,0,0,1,4.963-.259,48.328,48.328,0,0,0,10.185,6.721,35.338,35.338,0,0,0,16.234,3.981,17.61,17.61,0,0,0,6.928-1.344,4.953,4.953,0,0,0,3.154-4.963,5.326,5.326,0,0,0-1.034-3.257,11.116,11.116,0,0,0-3.722-2.74,61.606,61.606,0,0,0-7.342-3.154c-3.1-1.137-6.98-2.482-11.685-3.981A94.715,94.715,0,0,1,170.565,142a46.272,46.272,0,0,1-11.116-7.7,33.874,33.874,0,0,1-7.7-10.961,37.187,37.187,0,0,1-2.844-15.355c0-8.376,1.706-15.51,5.118-21.353a44.139,44.139,0,0,1,13.236-14.373A54.51,54.51,0,0,1,185.4,64.142,83.369,83.369,0,0,1,205.36,61.66a77.46,77.46,0,0,1,27.092,5.118,72.171,72.171,0,0,1,21.4,12.357,3.578,3.578,0,0,1,.207,5.17l-22.852,23.317a3.526,3.526,0,0,1-5.015,0,29.066,29.066,0,0,0-8.841-6,30.075,30.075,0,0,0-11.995-2.947,18.267,18.267,0,0,0-7.962,1.448,4.964,4.964,0,0,0,.1,9.2,86.143,86.143,0,0,0,10.185,3.826c4.808,1.5,9.875,3.309,15.2,5.325a63.08,63.08,0,0,1,14.735,7.91,41.305,41.305,0,0,1,11.116,11.891c2.9,4.7,4.395,10.547,4.395,17.475,0,8.117-1.6,15.2-4.86,21.2A47.243,47.243,0,0,1,235.554,192a55.368,55.368,0,0,1-17.785,8.944A68.539,68.539,0,0,1,197.605,203.943Z"
          transform="translate(582.573 -61.608)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="4"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M50.922,63.251a.927.927,0,0,1,.931-.931h57.027a97.983,97.983,0,0,1,19.7,2.016,49.877,49.877,0,0,1,17.475,7.031,38.2,38.2,0,0,1,12.46,13.494c3.154,5.635,4.757,12.719,4.757,21.2a37.975,37.975,0,0,1-22.542,35.881.916.916,0,0,0-.414,1.293c3.412,5.48,22.387,35.726,30.194,48.289a3.539,3.539,0,0,1-3.05,5.429H122.735a1.392,1.392,0,0,1-1.241-.724L95.8,150.678a.549.549,0,0,0-1.034.259v45.084a.927.927,0,0,1-.931.931H51.8a.927.927,0,0,1-.931-.931V63.251Zm43.533,55.89h8.893a36.284,36.284,0,0,0,5.015-.362,15.294,15.294,0,0,0,5.067-1.6,11.986,11.986,0,0,0,3.981-3.412,9.487,9.487,0,0,0,1.6-5.791,10.512,10.512,0,0,0-1.344-5.687,8.679,8.679,0,0,0-3.412-3.154,14.535,14.535,0,0,0-4.55-1.344,36.689,36.689,0,0,0-4.55-.31h-9.72A1.037,1.037,0,0,0,94.4,98.511V119.14Z"
          transform="translate(200.349 -58.856)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="4"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M246.467,145.808h-29.78a3.575,3.575,0,0,0-3.567,3.567v44a3.575,3.575,0,0,1-3.567,3.567h-38.1a3.575,3.575,0,0,1-3.567-3.567V65.877a3.575,3.575,0,0,1,3.567-3.567H209.4a3.575,3.575,0,0,1,3.567,3.567V104.4a3.575,3.575,0,0,0,3.567,3.567h29.78a3.575,3.575,0,0,0,3.567-3.567V65.877a3.575,3.575,0,0,1,3.567-3.567h38.311a3.575,3.575,0,0,1,3.567,3.567v127.5a3.575,3.575,0,0,1-3.567,3.567h-38.1a3.575,3.575,0,0,1-3.567-3.567v-44A3.689,3.689,0,0,0,246.467,145.808Z"
          transform="translate(688.3 -58.898)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="4"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M282.321,62.43H239.46a6.216,6.216,0,0,0-4.808,2.9l-36.915,57.6a.5.5,0,0,1-.931-.207c0-9.875.207-55.683.207-55.734.207-2.43-.1-4.395-3.05-4.5H163.717c-2.844.1-4.446,2.43-5.118,3.619l-33.709,57.13a.473.473,0,0,1-.931,0l2.688-56.872a3.437,3.437,0,0,0-3.619-3.878c-1.965-.052-36.243,0-36.243,0a5,5,0,0,0-4.7,4.5L81.2,193.907a3.077,3.077,0,0,0,3.464,3.154H122.3c1.6,0,2.585-.827,4.136-3.878l26.626-47.2a.407.407,0,0,1,.776.207v48.238a2.109,2.109,0,0,0,2.223,2.688H186.1c2.275,0,3.257.31,5.429-2.9L284.389,66.98C285.681,65.274,284.285,62.43,282.321,62.43Z"
          transform="translate(326.829 -58.397)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="4"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M265.391,62.342l-54.339-.052c-3.516.155-5.739,1.137-7.6,3.723L108.011,193.405a2.387,2.387,0,0,0,1.913,3.826h40.431a2.283,2.283,0,0,0,1.913-.982l68.608-92.908a2.407,2.407,0,0,1,4.343,1.448l.155,45.342H200.66a2.081,2.081,0,0,0-1.654.827l-19.7,27.4a2.02,2.02,0,0,0,1.654,3.206h44.515l.052,11.581a4.038,4.038,0,0,0,4.033,4.033h35.364a5.075,5.075,0,0,0,5.067-5.067V67.1A4.678,4.678,0,0,0,265.391,62.342Z"
          transform="translate(436.615 -58.981)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="4"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M225.528,65.011l-54.339-.052c-3.154.155-5.222.931-6.928,2.9a10.028,10.028,0,0,0-1.189,1.344s-60.749,81.172-61.732,82.413C94.671,160.09,86.4,162,78.178,161.538a31.77,31.77,0,0,1-10.444-2.016,24.665,24.665,0,0,1-8.634-5.791,27.965,27.965,0,0,1-5.894-9.048,30.674,30.674,0,0,1-2.171-11.891,33.38,33.38,0,0,1,2.068-12.1,26.588,26.588,0,0,1,5.791-9.2,25.646,25.646,0,0,1,8.634-5.791,27.9,27.9,0,0,1,10.651-2.016A30.546,30.546,0,0,1,90.9,106.269a24.87,24.87,0,0,1,6.876,4.55,3.611,3.611,0,0,0,5.274-.207L125.95,83a3.587,3.587,0,0,0-.569-5.118A70.924,70.924,0,0,0,104.96,66.665,85.655,85.655,0,0,0,75.49,61.65a81.986,81.986,0,0,0-28.643,5.015A68.617,68.617,0,0,0,23.581,81.038,67.315,67.315,0,0,0,7.967,103.477,72.253,72.253,0,0,0,2.28,132.74a72.044,72.044,0,0,0,5.687,29,68.378,68.378,0,0,0,15.614,22.439,69.489,69.489,0,0,0,23.266,14.528A81.825,81.825,0,0,0,75.49,203.83c30.814.31,46.17-17.992,50.047-23.111,1.344-1.81,55.528-74.864,55.528-74.864a2.407,2.407,0,0,1,4.343,1.448l.155,45.342H160.849a2.081,2.081,0,0,0-1.654.827l-19.7,27.4a2.02,2.02,0,0,0,1.654,3.206h44.515l.052,11.581a4.038,4.038,0,0,0,4.033,4.033h35.364a5.075,5.075,0,0,0,5.067-5.067V69.767A4.791,4.791,0,0,0,225.528,65.011Z"
          transform="translate(-2.28 -61.65)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="4"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({})
</script>

<style scoped lang="scss">
#animated-logo {
  #i {
    animation: fill_i 0.5s ease forwards 4s;
  }

  #a {
    animation: fill_a 0.5s ease forwards 4s;
  }

  #Car_Wash_Text {
    animation: fill_text 0.5s ease forwards 4s;
  }

  #a path {
    stroke-dasharray: 2856;
    stroke-dashoffset: 2856;
    animation: line-animation 3s ease forwards 1s;
  }

  #i path:nth-child(1) {
    stroke-dasharray: 221.5316162109375;
    stroke-dashoffset: 221.5316162109375;
    animation: line-animation 3s ease forwards 1s;
  }

  #i path:nth-child(2) {
    stroke-dasharray: 895.7376708984375;
    stroke-dashoffset: 895.7376708984375;
    animation: line-animation 3.1s ease forwards 1s;
  }

  #i path:nth-child(3) {
    stroke-dasharray: 989.5736694335938;
    stroke-dashoffset: 989.5736694335938;
    animation: line-animation 3.2s ease forwards 1s;
  }

  #Car_Wash_Text path:nth-child(1) {
    stroke-dasharray: 612.752197265625;
    stroke-dashoffset: 612.752197265625;
    animation: line-animation 3s ease forwards 1s;
  }

  #Car_Wash_Text path:nth-child(2) {
    stroke-dasharray: 654.3436889648438;
    stroke-dashoffset: 654.3436889648438;
    animation: line-animation 3.1s ease forwards 1s;
  }

  #Car_Wash_Text path:nth-child(3) {
    stroke-dasharray: 699.2804565429688;
    stroke-dashoffset: 699.2804565429688;
    animation: line-animation 3.2s ease forwards 1s;
  }

  #Car_Wash_Text path:nth-child(4) {
    stroke-dasharray: 875.0125122070312;
    stroke-dashoffset: 875.0125122070312;
    animation: line-animation 3.3s ease forwards 1s;
  }

  #Car_Wash_Text path:nth-child(5) {
    stroke-dasharray: 740.1441650390625;
    stroke-dashoffset: 740.1441650390625;
    animation: line-animation 3.4s ease forwards 1s;
  }

  #Car_Wash_Text path:nth-child(6) {
    stroke-dasharray: 1137.464599609375;
    stroke-dashoffset: 1137.464599609375;
    animation: line-animation 3.5s ease forwards 1s;
  }

  @keyframes line-animation {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fill_i {
    from {
      fill: transparent;
    }
    to {
      fill: #ffc940;
    }
  }

  @keyframes fill_a {
    from {
      fill: transparent;
    }
    to {
      fill: #ffc940;
    }
  }

  @keyframes fill_text {
    from {
      fill: transparent;
    }
    to {
      fill: white;
    }
  }
}
</style>
