<template>
  <v-app-bar
    color="primary"
    floating
    :extension-height="64"
    :extended="$vuetify.display.smAndDown"
  >
    <template
      #prepend
      v-if="$vuetify.display.smAndDown"
    >
      <v-app-bar-nav-icon @click="showNavigation = !showNavigation" />
    </template>
    <v-toolbar-title>
      {{ equipmentName && $vuetify.display.mdAndUp ? equipmentName + ' - ' : '' }}{{ maintenanceName }}
    </v-toolbar-title>
    <v-toolbar-items v-if="$vuetify.display.mdAndUp">
      <v-btn
        variant="text"
        rounded
        @click="showTask = true"
      >
        <v-icon class="mr-1">
          mdi-plus
        </v-icon>
        Add Task
      </v-btn>
      <!-- Display previous button if not first maintenance -->
      <v-btn
        :disabled="index === 0"
        variant="text"
        :loading="loading"
        @click="previous"
      >
        Previous
      </v-btn>
      <v-btn
        variant="text"
        :loading="loading"
        @click="index === queLength ? complete() : next()"
      >
        <span v-if="index === queLength - 1">Review</span>
        <span v-if="index === queLength">Confirm</span>
        <span v-else>Next</span>
      </v-btn>
    </v-toolbar-items>
    <template
      #extension
      v-if="$vuetify.display.smAndDown"
    >
      <v-btn
        variant="text"
        rounded
        @click="showTask = true"
      >
        <v-icon class="mr-1">
          mdi-plus
        </v-icon>
        Add Task
      </v-btn>
      <v-spacer />
      <!-- Display previous button if not first maintenance -->
      <v-btn
        :disabled="index === 0"
        variant="text"
        :loading="loading"
        size="x-large"
        @click="previous"
      >
        <v-icon size="x-large">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        variant="text"
        :loading="loading"
        size="x-large"
        @click="index === queLength ? complete() : next()"
      >
        <span v-if="index === queLength - 1">Review</span>
        <span v-if="index === queLength">Confirm</span>
        <v-icon
          v-else
          size="x-large"
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </template>
    <app-dialog
      v-model="showTask"
      scrollable
      max-width="800px"
      persistent
    >
      <task-form
        v-if="showTask"
        dialog
        :findable-id="activeMaintenance ? activeMaintenance.pivot.id : undefined"
        findable-type="MaintenanceMaintenanceSession"
        @close="cancel"
        @saved="saved"
      />
    </app-dialog>
  </v-app-bar>
</template>

<script lang="ts">
import TaskForm from '@/components/TaskForm.vue'
import { defineComponent } from 'vue'
import AppDialog from '@/components/AppDialog.vue'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useConductMaintenanceStore } from '@/stores/conductMaintenance'

export default defineComponent({
  components: { AppDialog, TaskForm },
  data() {
    return {
      showTask: false
    }
  },
  computed: {
    ...mapWritableState(useConductMaintenanceStore, [
      'index',
      'session',
      'showNavigation',
      'loading'
    ]),
    ...mapState(useConductMaintenanceStore, [
      'activeMaintenance'
    ]),

    maintenanceName() {
      return this.activeMaintenance?.display_name || 'Review'
    },
    equipmentName() {
      return this.activeMaintenance?.name || ''
    },
    queLength() {
      return this.session?.maintenances?.length || 0
    }
  },
  methods: {
    ...mapActions(useConductMaintenanceStore, [
      'previous',
      'next',
      'complete'
    ]),
    cancel() {
      this.showTask = false
    },
    saved() {
      this.showTask = false
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
