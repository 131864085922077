<template>
  <v-dialog
    v-model="showUpdateDialog"
    persistent
    fullscreen
    class="update-dialog"
  >
    <v-card
      class="update-dialog__card"
    >
      <v-spacer />
      <animated-logo />
      <h1>There is a new update! Updating to latest version now</h1>
      <p>
        A brand new version of the Car Wash Ai app is available. Please wait while we update. When complete you will be
        able to use all our amazing features.
      </p>
      <v-spacer />
      <v-btn
        color="primary"
        style="align-self: center"
        rounded
        max-width="80%"
        width="80%"
        loading
      />
      <v-spacer />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AnimatedLogo from '@/components/AnimatedLogo.vue'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Capacitor } from '@capacitor/core'

export default defineComponent({
  components: { AnimatedLogo },
  data() {
    return {
      showUpdateDialog: false
    }
  },
  created() {
    if (Capacitor.isNativePlatform()) {
      CapacitorUpdater.addListener('download', async () => {
        this.showUpdateDialog = true
      })
      CapacitorUpdater.addListener('updateAvailable', async (res) => {
        if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'phone') {
          return
        }
        await CapacitorUpdater.set(res.bundle)
      })
      CapacitorUpdater.notifyAppReady()
    }
  }
})
</script>

<style lang="scss" scoped>
.update-dialog {
  &__card {
    background-color: #1b1a1e;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px !important;
    gap: 16px;
  }
}
</style>
