<template>
  <v-list
    class="pa-0"
    nav
    v-model:opened="open"
  >
    <v-list-group
      v-if="stores.length > 1"
      fluid
    >
      <template #activator="{props}">
        <v-list-item
          v-bind="props"
          height="64px"
        >
          <template #prepend>
            <v-avatar color="primary">
              <v-img
                v-if="$auth.user!.file_id"
                :src="$file.url($auth.user!.file_id)"
              />
              <v-icon
                v-else
                color="white"
              >
                mdi-account
              </v-icon>
            </v-avatar>
          </template>
          <v-list-item-title class="text--primary">
            {{ activeStore ? activeStore.name : 'Company' }}
          </v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item
        :class="{ 'v-list-item--active': !activeStore }"
        @click.prevent="changeStore(null)"
      >
        <template #prepend>
          <v-avatar>
            <v-icon color="white">
              md:dashboard
            </v-icon>
          </v-avatar>
        </template>
        <v-list-item-title class="text--primary">
          Company
        </v-list-item-title>
      </v-list-item>
      <template
        v-for="store in stores"
        :key="store.id"
      >
        <v-list-item
          :class="{ 'v-list-item--active': activeStore?.id === store.id }"
          @click.prevent="changeStore(store.id)"
        >
          <template #prepend>
            <v-avatar>
              <v-img
                v-if="store.file_id"
                :src="$file.url(store.file_id)"
                height="34px"
              />
              <v-icon
                v-else
                icon="md:store"
                color="white"
              />
            </v-avatar>
          </template>
          <v-list-item-title
            v-text="store.name"
          />
        </v-list-item>
      </template>
    </v-list-group>
    <v-list-item
      height="65"
      v-else
    >
      <template #prepend>
        <app-avatar
          :file-id="$store.stores[0].file_id"
          color="white"
          size="40"
          icon-color="black"
          icon="mdi-store"
        />
      </template>
      <v-list-item-title>
        {{ stores[0].name }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStoreStore } from '@/stores/store'
import { mapState } from 'pinia'
import AppAvatar from '@/components/AppAvatar.vue'

export default defineComponent({
  components: { AppAvatar },
  props: {
    inDrawer: {
      type: Boolean,
      default: false
    }
  },
  emits: ['storeChanged'],
  data() {
    return {
      open: []
    }
  },
  computed: {
    ...mapState(useStoreStore, [
      'stores',
      'activeStore'
    ])
  },
  methods: {
    async changeStore(storeId: string | null) {
      this.open = []
      const companyRouteSupported = this.$route.matched[0].path.includes('storeId?') || this.$route.matched[0].path.toLowerCase().indexOf('storeId') === -1
      const storeRouteSupported = this.$route.matched[0].path.includes('storeId')

      let route: string
      if ((!storeId && companyRouteSupported) || (storeId && storeRouteSupported)) {
        route = this.$route.name
      } else {
        route = this.ROUTES.DASHBOARD
      }

      await this.$router.push(this.$routeTo(route, { storeId: storeId }))
    }
  }
})
</script>

<style lang="scss" scoped>
.primary-border {
  border: 2px solid var(--v-primary-base) !important;

  :deep(.v-icon.mdi-chevron-down) {
    color: var(--v-primary-base);
  }
}
</style>
