import RouteDesigner from '@/router/RouteDesigner'
import Authentication from '@/middleware/Authentication'
import Authorization from '@/middleware/Authorization'
import ActiveStore from '@/middleware/ActiveStore'
import PreventCorporateForSingleStoreCompanies from '@/middleware/PreventCorporateForSingleStoreCompanies'
import ForceTypes from '@/middleware/ForceTypes'

export const ROUTES = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  FORGOT_PASSWORD_SENT: 'forgot-password-sent',
  SET_PASSWORD: 'set-password',
  COMPLETE_PROFILE: 'complete-profile',
  REGISTER: 'register',
  MY_ACCOUNT: 'my-account',
  CHECKOUT: 'checkout',

  DASHBOARD: 'dashboard',

  TASK_CENTER: 'task-center',
  TASK_CENTER_CREATE: 'task-center-create',
  TASK_CENTER_EDIT: 'task-center-edit',

  INCIDENT_REPORT_DASHBOARD: 'incident-report-dashboard',
  INCIDENT_REPORT_SUBMISSIONS: 'incident-report-submissions',
  INCIDENT_REPORT_FORM: 'incident-report-form',
  INCIDENT_REPORT_SUBMISSION: 'incident-report-submission',
  INCIDENT_REPORT_REVIEW: 'incident-report-review',

  MAINTENANCE: 'store-settings-maintenance',
  MAINTENANCE_ADD: 'store-settings-maintenance-add',
  MAINTENANCE_CATALOGUE: 'store-settings-maintenance-catalogue',
  MAINTENANCE_NEW: 'store-settings-maintenance-new',
  MAINTENANCE_EDIT: 'store-settings-maintenance-edit',
  MAINTENANCE_QUEUE: 'maintenance-queue',
  MAINTENANCE_CONDUCT: 'maintenance-conduct',
  MAINTENANCE_SUPPLIES: 'maintenance-supplies',

  CHECKLISTS: 'checklist',
  CHECKLIST_VIEW: 'checklist-view',
  CHECKLIST_HISTORY: 'checklist-history',
  CHECKLIST_HISTORY_VIEW: 'checklist-history-view',
  CHECKLIST_ANSWER_HISTORY: 'checklist-answer-history',

  INVENTORY: 'inventory',
  INVENTORY_ADD: 'inventory-add',
  INVENTORY_EDIT: 'inventory-edit',

  STORE_SETTINGS: 'store-settings',
  STORE_SETTINGS_SUPPLIERS: 'store-settings-suppliers',
  STORE_SETTINGS_USERS: 'store-settings-users',
  STORE_SETTINGS_CHECKLISTS: 'store-settings-checklists',
  STORE_SETTINGS_CHECKLISTS_ADD: 'store-settings-checklists-add',
  STORE_SETTINGS_CHECKLISTS_CATALOGUE: 'store-settings-checklists-catalogue',
  STORE_SETTINGS_CHECKLISTS_NEW: 'store-settings-checklists-new',
  STORE_SETTINGS_CHECKLISTS_EDIT: 'store-settings-checklists-edit',
  STORE_SETTINGS_EQUIPMENT: 'store-settings-equipment',
  STORE_SETTINGS_EQUIPMENT_VIEW: 'store-settings-equipment-view',
  STORE_SETTINGS_EQUIPMENT_VIEW_ACTIVITY:
    'store-settings-equipment-view-activity',
  STORE_SETTINGS_EQUIPMENT_VIEW_COMMENTS:
    'store-settings-equipment-view-comments',
  STORE_SETTINGS_EQUIPMENT_VIEW_MAINTENANCE:
    'store-settings-equipment-view-maintenance',
  STORE_SETTINGS_EQUIPMENT_VIEW_FILES: 'store-settings-equipment-view-files',
  STORE_SETTINGS_EQUIPMENT_ADD: 'store-settings-equipment-add',
  STORE_SETTINGS_EQUIPMENT_CATALOGUE: 'store-settings-equipment-catalogue',
  STORE_SETTINGS_EQUIPMENT_NEW: 'store-settings-equipment-new',
  STORE_SETTINGS_EQUIPMENT_GROUPS: 'store-settings-equipment-groups',
  STORE_SETTINGS_EQUIPMENT_GROUPS_ADD: 'store-settings-equipment-groups-add',
  STORE_SETTINGS_EQUIPMENT_GROUPS_EDIT: 'store-settings-equipment-groups-edit',
  STORE_SETTINGS_EQUIPMENT_ORDER: 'store-settings-equipment-order',
  STORE_SETTINGS_MAINTENANCE: 'store-settings-maintenance',
  STORE_SETTINGS_WORKFLOW: 'store-settings-workflow',
  STORE_SETTINGS_WORKFLOW_NEW: 'store-settings-workflow-new',
  STORE_SETTINGS_WORKFLOW_EDIT: 'store-settings-workflow-edit',

  COMPANY_SETTINGS_GENERAL: 'company-settings-general',
  COMPANY_SETTINGS_ROLES: 'company-settings-roles',
  COMPANY_SETTINGS_ROLE_ADD: 'company-settings-role-add',
  COMPANY_SETTINGS_ROLE_EDIT: 'company-settings-role-edit',
  COMPANY_SETTINGS_USERS: 'company-settings-users',
  COMPANY_SETTINGS_SUBSCRIPTIONS: 'company-settings-subscriptions',
  COMPANY_SETTINGS_WAREHOUSES: 'company-settings-warehouses',

  MOBILE_DRAWER: 'mobile-drawer',
  PROFILE: 'profile'
} as const

RouteDesigner.pattern(
  'storeId',
  '[\\da-fA-F]{8}-[\\da-fA-F]{4}-[\\da-fA-F]{4}-[\\da-fA-F]{4}-[\\da-fA-F]{12}'
)

RouteDesigner
  .setNotFound('ErrorPage')
  .layout('Empty')


RouteDesigner.group('', function() {
  RouteDesigner.group('', function() {
    RouteDesigner.route('', 'LoginPage', ROUTES.LOGIN)
    RouteDesigner.route('/forgot-password', 'ForgotPasswordPage', ROUTES.FORGOT_PASSWORD)
    RouteDesigner.route('/forgot-password/:token', 'ResetPasswordPage', ROUTES.SET_PASSWORD)
  })
    .layout('Login')

  RouteDesigner.route('/register', 'RegisterPage', ROUTES.REGISTER)
    .layout('Empty')


  RouteDesigner.group('', function() {
    RouteDesigner.group('/:storeId?', function() {
      RouteDesigner.route('/dashboard', 'AppDashboard', ROUTES.DASHBOARD)
      RouteDesigner.route('/mobile-drawer', 'MobileDrawer', ROUTES.MOBILE_DRAWER)
      RouteDesigner.route('/my-account', 'MyAccount', ROUTES.MY_ACCOUNT)

      // Task Center
      RouteDesigner.route('/task', 'TaskCenter', ROUTES.TASK_CENTER)
        .child('create', 'TaskCenterNew', ROUTES.TASK_CENTER_CREATE)
        .child(':taskId', 'TaskCenterEdit', ROUTES.TASK_CENTER_EDIT)

      // Reports
      RouteDesigner.group('/reports', function() {
        RouteDesigner.route('/checklist/history', 'ChecklistHistory', ROUTES.CHECKLIST_HISTORY)
        RouteDesigner.route('/checklist/history/:checklistId', 'ChecklistHistoryView', ROUTES.CHECKLIST_HISTORY_VIEW).whereNumber('checklistId')
        RouteDesigner.route('/checklist/answer-history', 'ChecklistAnswerHistory', ROUTES.CHECKLIST_ANSWER_HISTORY)
      })

      // Incident Report
      RouteDesigner.group('/incident-report', function() {
        RouteDesigner.route('', 'IncidentReportDashboard', ROUTES.INCIDENT_REPORT_DASHBOARD)
        RouteDesigner.route('/form', 'IncidentReportForm', ROUTES.INCIDENT_REPORT_FORM)
        RouteDesigner.route('/submission', 'IncidentReportSubmission', ROUTES.INCIDENT_REPORT_SUBMISSION)
        RouteDesigner.route('/:incidentReportId', 'IncidentReportReview', ROUTES.INCIDENT_REPORT_REVIEW)
          .whereNumber('incidentReportId')
      })

      // Inventory
      RouteDesigner.route('/inventories', 'InventoryPage', ROUTES.INVENTORY)
      RouteDesigner.route('/inventory', 'InventoryUpsertPage', ROUTES.INVENTORY_ADD)
      RouteDesigner.route('/inventory/:itemId', 'InventoryUpsertPage', ROUTES.INVENTORY_EDIT)
    })

    // Routes without company views
    RouteDesigner.group('/:storeId', function() {
      // Checklists
      RouteDesigner.route('/checklist', 'ChecklistIndex')
        .child(':checklistInstanceId?', 'ChecklistView', ROUTES.CHECKLISTS)

      // Maintenance
      RouteDesigner.group('/maintenances', function() {
        RouteDesigner.route('/add', 'MaintenanceAdd', ROUTES.MAINTENANCE_ADD)
        RouteDesigner.route('/new', 'MaintenanceUpsert', ROUTES.MAINTENANCE_NEW)
        RouteDesigner.route('/catalogue', 'MaintenanceCatalogue', ROUTES.MAINTENANCE_CATALOGUE)
        RouteDesigner.route('/edit/:maintenanceId', 'MaintenanceUpsert', ROUTES.MAINTENANCE_EDIT).whereNumber('maintenanceId')

        RouteDesigner.route('/queue', 'MaintenanceQueue', ROUTES.MAINTENANCE_QUEUE)
        RouteDesigner.route('/conduct', 'MaintenanceConduct', ROUTES.MAINTENANCE_CONDUCT).layout('ConductMaintenance')
        RouteDesigner.route('/supplies', 'MaintenanceSupplies', ROUTES.MAINTENANCE_SUPPLIES)
      })

      // Store Settings
      RouteDesigner.group('/settings', function() {
        RouteDesigner.route('', 'StoreSettingsGeneral', ROUTES.STORE_SETTINGS)
        RouteDesigner.route('/suppliers', 'StoreSettingsSuppliers', ROUTES.STORE_SETTINGS_SUPPLIERS)
        RouteDesigner.route('/users', 'StoreSettingsUsers', ROUTES.STORE_SETTINGS_USERS)
        RouteDesigner.route('/maintenance', 'StoreSettingsMaintenance', ROUTES.STORE_SETTINGS_MAINTENANCE)

        RouteDesigner.group('/checklists', function() {
          RouteDesigner.route('', 'StoreSettingsChecklists', ROUTES.STORE_SETTINGS_CHECKLISTS)
          RouteDesigner.route('/add', 'StoreSettingsChecklistAdd', ROUTES.STORE_SETTINGS_CHECKLISTS_ADD)
          RouteDesigner.route('/add/catalogue', 'StoreSettingsChecklistCatalogue', ROUTES.STORE_SETTINGS_CHECKLISTS_CATALOGUE)
          RouteDesigner.route('/new', 'StoreSettingsChecklistUpsert', ROUTES.STORE_SETTINGS_CHECKLISTS_NEW)
          RouteDesigner.route('/edit/:checklistId', 'StoreSettingsChecklistUpsert', ROUTES.STORE_SETTINGS_CHECKLISTS_EDIT)
            .whereNumber('checklistId')
        })

        RouteDesigner.group('/equipment', function() {
          RouteDesigner.route('', 'StoreSettingsEquipment', ROUTES.STORE_SETTINGS_EQUIPMENT)
          RouteDesigner.route('/order', 'StoreSettingsEquipmentOrder', ROUTES.STORE_SETTINGS_EQUIPMENT_ORDER)

          RouteDesigner.route('/:equipmentId', 'StoreSettingsEquipmentView')
            .whereNumber('equipmentId')
            .child('', 'StoreSettingsEquipmentViewSettings', ROUTES.STORE_SETTINGS_EQUIPMENT_VIEW)
            .child('/activity', 'StoreSettingsEquipmentViewActivity', ROUTES.STORE_SETTINGS_EQUIPMENT_VIEW_ACTIVITY)
            .child('/comments', 'StoreSettingsEquipmentViewComments', ROUTES.STORE_SETTINGS_EQUIPMENT_VIEW_COMMENTS)
            .child('/maintenance', 'StoreSettingsEquipmentViewMaintenance', ROUTES.STORE_SETTINGS_EQUIPMENT_VIEW_MAINTENANCE)
            .child('/files', 'StoreSettingsEquipmentViewFiles', ROUTES.STORE_SETTINGS_EQUIPMENT_VIEW_FILES)


          RouteDesigner.route('/add', 'StoreSettingsEquipmentAdd', ROUTES.STORE_SETTINGS_EQUIPMENT_ADD)
          RouteDesigner.route('/add/catalogue', 'StoreSettingsEquipmentCatalogue', ROUTES.STORE_SETTINGS_EQUIPMENT_CATALOGUE)
          RouteDesigner.route('/add/new', 'StoreSettingsEquipmentNew', ROUTES.STORE_SETTINGS_EQUIPMENT_NEW)
        })

        RouteDesigner.group('/equipment-group', function() {
          RouteDesigner.route('', 'StoreSettingsEquipmentGroups', ROUTES.STORE_SETTINGS_EQUIPMENT_GROUPS)
          RouteDesigner.route('/add', 'StoreSettingsEquipmentGroupsUpsert', ROUTES.STORE_SETTINGS_EQUIPMENT_GROUPS_ADD)
          RouteDesigner.route('/:equipmentGroupId', 'StoreSettingsEquipmentGroupsUpsert', ROUTES.STORE_SETTINGS_EQUIPMENT_GROUPS_EDIT)
            .whereNumber('equipmentGroupId')
        })

        RouteDesigner.group('/workflows', function() {
          RouteDesigner.route('', 'StoreSettingsWorkflows', ROUTES.STORE_SETTINGS_WORKFLOW)
          RouteDesigner.route('/new', 'StoreSettingsWorkflowUpsert', ROUTES.STORE_SETTINGS_WORKFLOW_NEW)
          RouteDesigner.route('/:workflowId', 'StoreSettingsWorkflowUpsert', ROUTES.STORE_SETTINGS_WORKFLOW_EDIT)
            .whereNumber('workflowId')
        })
      })
    })

    // Company Settings
    RouteDesigner.group('/company-settings', function() {
      RouteDesigner.route('', 'CompanySettingsGeneral', ROUTES.COMPANY_SETTINGS_GENERAL)
      RouteDesigner.route('/roles', 'CompanySettingsRoles', ROUTES.COMPANY_SETTINGS_ROLES)
      RouteDesigner.route('/role', 'CompanySettingsRoleUpsert', ROUTES.COMPANY_SETTINGS_ROLE_ADD)
      RouteDesigner.route('/role/:roleId', 'CompanySettingsRoleUpsert', ROUTES.COMPANY_SETTINGS_ROLE_EDIT).whereNumber('roleId')
      RouteDesigner.route('/users', 'CompanySettingsUsers', ROUTES.COMPANY_SETTINGS_USERS)
      RouteDesigner.route('/subscriptions', 'CompanySettingsSubscriptions', ROUTES.COMPANY_SETTINGS_SUBSCRIPTIONS)
      RouteDesigner.route('/warehouses', 'CompanySettingsWarehouses', ROUTES.COMPANY_SETTINGS_WAREHOUSES)
    })
    RouteDesigner.route('/checkout', 'CheckoutPage', ROUTES.CHECKOUT)
  })
    .layout('Default')
    .props()
    .middleware([
      Authorization,
      ActiveStore,
      PreventCorporateForSingleStoreCompanies
    ])
})
  .middleware([
    Authentication,
    ForceTypes
  ])


