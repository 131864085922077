<template>
  <v-app-bar
    ref="toolbar"
    class="app-toolbar"
    flat
    color="white"
    :height="height"
    :extension-height="extensionHeight"
  >
    <div
      class="d-flex flex-column"
      style="max-width: 80%"
    >
      <v-menu v-model="menu">
        <template #activator="{ props }">
          <v-toolbar-title
            class="app-toolbar__title"
            v-bind="$vuetify.display.smAndDown && stores.length > 1 ? props : {}"
          >
            {{ $store.activeStore ? $store.activeStore.name : 'Company' }}
            <v-icon
              v-if="$vuetify.display.smAndDown && stores.length > 1"
              class="rotate-180"
              :class="{'rotate-180--active': menu}"
            >
              mdi-chevron-down
            </v-icon>
          </v-toolbar-title>
        </template>
        <v-list>
          <v-list-item
            :class="{ 'v-list-item--active': !activeStore }"
            @click.prevent="changeStore(null)"
          >
            <template #prepend>
              <v-avatar>
                <v-icon>
                  md:dashboard
                </v-icon>
              </v-avatar>
            </template>
            <v-list-item-title class="text--primary">
              Company
            </v-list-item-title>
          </v-list-item>
          <template
            v-for="store in stores"
            :key="store.id"
          >
            <v-list-item
              :class="{ 'v-list-item--active': activeStore?.id === store.id }"
              @click.prevent="changeStore(store.id)"
            >
              <template #prepend>
                <v-avatar>
                  <v-img
                    v-if="store.file_id"
                    :src="$file.url(store.file_id)"
                    height="34px"
                  />
                  <v-icon
                    v-else
                    icon="md:store"
                    color="white"
                  />
                </v-avatar>
              </template>
              <v-list-item-title
                v-text="store.name"
              />
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-toolbar-title class="app-toolbar__sub-title">
        {{ title }}
      </v-toolbar-title>
    </div>

    <v-spacer />

    <v-badge
      v-ripple
      color="error"
      floating
      :model-value="notificationCount > 0"
      class="app-toolbar__notification-icon"
    >
      <template #badge>
        {{ notificationCount }}
      </template>
      <a>
        <v-icon
          color="primary"
        >
          mdi-bell
        </v-icon>
      </a>
    </v-badge>

    <template #extension>
      <div
        class="w-100"
        ref="toolbar-extension"
        id="toolbar-extension"
      />
    </template>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useStoreStore } from '@/stores/store'

export default defineComponent({
  data: () => ({
    notificationCount: 1 as number,
    extensionHeight: 0,
    menu: false
  }),
  computed: {
    ...mapState(useStoreStore, ['stores', 'activeStore']),
    height() {
      return 80 + Math.max(Number(getComputedStyle(document.documentElement).getPropertyValue('--v-safe-area-top').replace('px', '')) - 20, 0)
    },
    title() {
      return document.title || 'Car Wash Ai'
    }
  },
  mounted() {
    this.getNotificationCount()
    if (!this.$auth.user) return
    const channel = this.$pusher.subscribe(this.$auth.user.id + '-notification')
    channel.bind('notification', () => {
      this.notificationCount++
    })

    const target = this.$refs['toolbar-extension'] as Node
    const observer = new MutationObserver(this.updateExtensionHeight)
    observer.observe(target, { characterData: true, attributes: true, childList: true, subtree: true })
  },
  methods: {
    async getNotificationCount() {
      const {
        data: { count, errors, message },
        status
      } = await this.$http.get('/notifications/count').catch((e) => e)
      if (this.$error(status, message, errors)) {
        return
      }
      this.notificationCount = count
    },
    updateExtensionHeight() {
      this.extensionHeight = this.$refs['toolbar-extension']!.clientHeight
    },
    async changeStore(storeId: string | null) {
      const companyRouteSupported = this.$route.matched[0].path.includes('storeId?') || this.$route.matched[0].path.toLowerCase().indexOf('storeId') === -1
      const storeRouteSupported = this.$route.matched[0].path.includes('storeId')

      let route: string
      if ((!storeId && companyRouteSupported) || (storeId && storeRouteSupported)) {
        route = this.$route.name
      } else {
        route = this.ROUTES.DASHBOARD
      }

      await this.$router.push(this.$routeTo(route, { storeId: storeId }))
    }
  }
})
</script>

<style lang="scss">
.app-toolbar {
  z-index: 4;
  padding-left: 0;

  &:after {
    content: "";
    background: linear-gradient(170deg, #502075 50%, #06ffe4 100%);
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &__title {
    font-weight: bold;
  }

  &__dropdown-menu {
    border-radius: 3px;

    &__toolbar-items {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 3px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      min-height: 48px;
      padding: 10px 15px;
    }

    &__notifications {
      min-width: 250px;
      min-height: 250px;
      overflow: auto;
      max-height: calc(100vh - 120px);

      &__card {
        &--read {
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }
  }

  //.v-toolbar__extension {
  //  padding: 0;
  //}
}
</style>
