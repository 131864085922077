import errorHandler from '@/plugins/errorHandler'
import axios from 'axios'
import { type MaybeRef, toValue } from 'vue'
import { useAppStore } from '@/stores/app'

type Return = {
  updateField: (field: string, value: any) => Promise<void>
}

export default function useEditTask(task: MaybeRef<App.Models.Task>): Return {
  const updateField = async (field: string, value: any) => {
    const { data: { errors, message }, status } = await axios.put(`/tasks/${toValue(task).id}`, {
      field,
      value
    }).catch((e) => e)
    if (errorHandler(status, message, errors)) {
      return
    }
    useAppStore().tablesShouldRefresh = true
  }

  return {
    updateField
  }
}
