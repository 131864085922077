<template>
  <app-dialog
    v-model="show"
    persistent
    no-click-animation
    max-width="500px"
  >
    <v-card>
      <v-card-title>You haven't completed your maintenance</v-card-title>
      <v-card-text>
        You haven't completed your maintenance. Either finish doing maintenance or mark the rest of the items
        as skipped
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          :loading="loading"
          @click="markSkipped"
        >
          Mark Skipped
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="finishMaintenance"
        >
          Finish Maintenance
        </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>

<script lang="ts">
import AppDialog from '@/components/AppDialog.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AppDialog },
  data() {
    return {
      show: false,
      loading: false,
      session: null as null | App.Models.MaintenanceSession
    }
  },
  mounted() {
    this.getSession()
  },
  methods: {
    async markSkipped() {
      this.loading = true
      const {
        data: { message, errors },
        status
      } = await this.$http.post(`/maintenance-sessions/mark-skipped/${this.session!.id}`).catch((e) => e)
      this.loading = false
      if (this.$error(status, message, errors)) {
        return
      }
      this.show = false
    },
    finishMaintenance() {
      this.$router.push(this.$routeTo(this.ROUTES.MAINTENANCE_CONDUCT))
      this.show = false
    },
    async getSession() {
      if (this.$store.isCorporate) {
        return
      }
      const {
        data: { session, message, errors },
        status
      } = await this.$http.get('/maintenance-sessions').catch((e) => e)
      if (this.$error(status, message, errors)) {
        return
      }
      this.session = session

      if (this.$route.name === this.ROUTES.MAINTENANCE_CONDUCT || this.$route.name === this.ROUTES.MAINTENANCE_QUEUE) {
        return
      }
      
      if (this.session) {
        this.show = true
      }
    }
  }
})
</script>

<style scoped></style>
