<template>
  <v-navigation-drawer
    v-model="showFilterSidebar"
    location="right"
    temporary
    :style="$attrs.style"
    :order="-1"
    :width="null"
    class="task-filter-drawer"
  >
    <template #prepend>
      <v-card>
        <v-card-title class="task-filter-drawer__card__title">
          <v-btn
            icon
            variant="text"
            @click="showFilterSidebar = false"
          >
            <v-icon color="red">
              md:exit_to_app
            </v-icon>
          </v-btn>
          <h4>Filters</h4>
          <v-spacer />
          <v-btn
            class="bg-primary"
            @click="showSaveFilterDialog = true"
          >
            Save Filter
          </v-btn>
        </v-card-title>
      </v-card>
    </template>
    <v-card
      flat
      width="500px"
      class="task-filter-drawer__card"
      v-if="showFilterSidebar"
    >
      <v-card-text class="task-filter-drawer__card__body">
        <v-form>
          <task-preset-filters ref="presetSelector" />
          <template v-if="$vuetify.display.smAndDown">
            <v-select
              v-model="filters.sort_field"
              :items="sortFields"
              label="Sort Fields"
            />
            <v-select
              v-model="filters.sort_direction"
              :items="sortDirections"
              label="Sort Direction"
            />
          </template>
          <app-auto-complete
            v-if="$store.isCorporate"
            v-model="filters.store_ids"
            field-name="stores"
            item-value="id"
            item-title="name"
            endpoint="/stores"
            multiple
            label="Stores"
          />
          <v-select
            v-model="filters.types"
            multiple
            chips
            :items="types"
            label="Type"
          />
          <v-select
            v-model="filters.statuses"
            multiple
            chips
            :items="statuses"
            item-value="value"
            label="Status"
          >
            <template #chip="{ item, index }">
              <v-chip
                v-if="index < 2"
                :color="item.color"
              >
                <span>{{ item.title }} </span>
              </v-chip>
              <span
                v-if="index === 2"
                class="text-grey ml-2 text-caption"
              >
                (+{{ filters.statuses.length - 2 }} others)
              </span>
            </template>
          </v-select>
          <h4>Priority</h4>
          <v-btn-toggle
            v-model="filters.priority"
            mandatory
            style="width: 100%"
          >
            <template
              v-for="priority in priorities"
              :key="priority.id"
            >
              <v-btn

                class="mt-2"
                variant="text"
                :value="priority.value"
              >
                {{ priority.title }}
              </v-btn>
            </template>
          </v-btn-toggle>
          <equipment-ac
            v-model="filters.equipment_ids"
            multiple
            label="Equipment"
          />
          <users-ac
            v-model="filters.assigned_to_ids"
            :store-id="$route.params.storeId"
            multiple
            label="Assigned To"
          />
          <users-ac
            v-model="filters.assigned_by_ids"
            :store-id="$route.params.storeId"
            multiple
            label="Assigned By"
          />
          <users-ac
            v-model="filters.created_by_ids"
            :store-id="$route.params.storeId"
            multiple
            label="Created By"
          />
          <h4>Due Date:</h4>
          <v-row :no-gutters="$vuetify.display.smAndDown">
            <v-col
              cols="12"
              md="6"
            >
              <date-picker-input
                v-model="filters.due_date[0]"
                label="From"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <date-picker-input
                v-model="filters.due_date[1]"
                label="To"
              />
            </v-col>
          </v-row>
          <h4>Completed Date:</h4>
          <v-row :no-gutters="$vuetify.display.smAndDown">
            <v-col
              cols="12"
              md="6"
            >
              <date-picker-input
                v-model="filters.completed_date[0]"
                label="From"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <date-picker-input
                v-model="filters.completed_date[1]"
                label="To"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <app-dialog
      v-model="showSaveFilterDialog"
      :persistent="saving"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Save Filter Preset</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              ref="form"
              v-model="presetName"
              label="Preset Name"
              :rules="rules.required"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="showSaveFilterDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="saving"
            @click="savePreset"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </app-dialog>
  </v-navigation-drawer>
</template>

<script lang="ts">
import UsersAc from '@/components/UsersAc.vue'
import EquipmentAc from '@/components/EquipmentAc.vue'
import { mapWritableState } from 'pinia'
import { useTaskStore } from '@/stores/task'
import { defineComponent } from 'vue'
import DatePickerInput from '@/components/DatePickerInput.vue'
import TaskPresetFilters from '@/components/TaskPresetFilters.vue'
import AppDialog from '@/components/AppDialog.vue'
import validators from '@/mixins/validators'
import taskStatuses from '@/enums/taskStatuses'
import AppAutoComplete from '@/components/AppAutoComplete.vue'

export default defineComponent({
  components: {
    AppAutoComplete,
    AppDialog,
    TaskPresetFilters,
    DatePickerInput,
    EquipmentAc,
    UsersAc
  },
  mixins: [validators],
  data() {
    return {
      priorities: [
        { value: -1, title: 'All' },
        { value: 0, title: 'None' },
        { value: 1, title: 'Low' },
        { value: 2, title: 'Medium' },
        { value: 3, title: 'High' }
      ] as { value: App.Enums.Task.Priority; title: string }[],
      statuses: taskStatuses,
      types: [
        { title: 'Repair', value: 'repair' },
        { title: 'Gen. Task', value: 'task' },
        { title: 'Contact Customer', value: 'customer' }
      ] as { value: App.Enums.Task.Type; title: string }[],
      sortFields: [
        { title: 'Priority', value: 'priority' },
        { title: 'Type', value: 'type' },
        { title: 'Name', value: 'name' },
        { title: 'Status', value: 'status' },
        { title: 'Due Date', value: 'due_date' },
        { title: 'Equipment', value: 'equipment_id' },
        { title: 'Store', value: 'store_id' },
        { title: 'Assigned', value: 'assigned_to_id' }
      ],
      sortDirections: [
        { title: 'Ascending', value: 'ASC' },
        { title: 'Descending', value: 'DESC' }
      ],
      showSaveFilterDialog: false,
      saving: false,
      presetName: null
    }
  },
  computed: {
    ...mapWritableState(useTaskStore, ['filters', 'showFilterSidebar'])
  },
  watch: {
    showSaveFilterDialog(val) {
      if (!val) {
        this.presetName = null
        this.$refs.form.resetValidation()
      }
    }
  },
  beforeUnmount() {
    useTaskStore().clearFilters()
  },
  methods: {
    async savePreset() {
      if (!this.$refs.form.validate()) return
      this.saving = true
      const {
        data: { filter, message, errors },
        status
      } = await this.$http
        .post('/preset-task-filters', {
          name: this.presetName,
          filters: this.filters
        })
        .catch((e) => e)
      this.saving = false
      if (this.$error(status, message, errors)) return
      /* @ts-expect-error */
      this.$refs.presetSelector.reset()
      this.showSaveFilterDialog = false
      this.$refs.presetSelector.filterPreset = filter
    }
  }
})
</script>

<style scoped lang="scss">
.task-filter-drawer {
  &__card {
    &__title {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;

      h4 {
        margin-bottom: 0 !important;
        font-size: 1.525em !important;
      }
    }

    &__body {
      .v-btn-toggle {
        width: 100%;
        margin-bottom: 20px;

        .v-btn {
          width: 20%;
          margin: 0;
        }
      }
    }
  }
}
</style>
