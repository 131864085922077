<template>
  <component
    :is="layout"
    v-if="layout"
  >
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UpdateDialog from '@/components/UpdateDialog.vue'
import { SplashScreen } from '@capacitor/splash-screen'

export default defineComponent({
  components: { UpdateDialog },
  head: {
    titleTemplate: '%s | Car Wash Ai'
  },
  computed: {
    layout() {
      if (!this.$route) return null
      return this.$route.meta?.layout ? this.$route.meta.layout + 'Layout' : 'EmptyLayout'
    }
  },
  mounted() {
    SplashScreen.hide()
  }
})
</script>
