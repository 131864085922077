import { useStoreStore } from '@/stores/store'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { $auth } from '@/plugins/auth'

export default class PreventCorporateForSingleStoreCompanies implements App.Middleware.Instance {
  async handle(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: App.Middleware.Caller,
    cancel: NavigationGuardNext
  ): Promise<void> {
    if (!$auth.user) {
      return
    }
    const storeStore = useStoreStore()
    if (storeStore.stores.length === 1 && to.params.storeId === '') {
      return cancel('/' + storeStore.stores[0].id + to.path)
    }
    await next(to, from, cancel)
  }
}
