<template>
  <tr>
    <td class="d-flex">
      <v-icon
        size="40px"
        class="mr-1"
      >
        mdi-file-cog-outline
      </v-icon>
      <div style="line-height: 20px">
        <p class="mb-0">
          Files
        </p>
        {{ task.files!.length }} Attached Files
      </div>
    </td>
    <td align="right">
      <file-upload-btn
        outlined
        color="primary"
        return-object
        multiple
        @change="addFiles"
      >
        <v-icon>mdi-plus</v-icon>
        Add File
      </file-upload-btn>
    </td>
  </tr>
  <tr style="border-spacing: 0 10px;">
    <td colspan="2">
      <v-row
        class="my-2"
        style="min-height: 40px"
      >
        <v-col
          v-for="file in task.files"
          :key="file.id"
          cols="6"
          md="4"
        >
          <light-box-image
            :file-id="file.id"
            contain
            position="center center"
            :resolution="'thumbnail'"
            v-slot="{ isHovering }"
          >
            <div class="d-flex">
              <v-spacer />
              <v-icon
                v-show="isHovering || $vuetify.display.smAndDown"
                @click.stop.prevent="$http.download($file.url(file.id))"
              >
                mdi-download
              </v-icon>
              <v-icon
                v-show="isHovering || $vuetify.display.smAndDown"
                @click.stop.prevent="deleteFile(file)"
              >
                mdi-delete
              </v-icon>
            </div>
          </light-box-image>
        </v-col>
      </v-row>
    </td>
  </tr>
</template>

<script setup lang="ts">
import FileUploadBtn from '@/components/FileUploadBtn.vue'
import useEditTask from '@/composables/useEditTask'
import LightBoxImage from '@/components/LightBoxImage.vue'

const task = defineModel<App.Models.Task>({ required: true })
const { updateField } = useEditTask(task)

const addFiles = (files: App.Models.File[]) => {
  task.value.files = task.value.files!.concat(files)
  updateField('files', task.value.files)
}

const deleteFile = (file: App.Models.File) => {
  task.value.files!.splice(task.value.files!.findIndex((i) => i.id === file.id), 1)
  if (!task.value.id) {
    // Skip updating when creating a new task
    return
  }
  updateField('files', task.value.files)
}
</script>

<style scoped></style>
