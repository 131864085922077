<template>
  <app-auto-complete
    ref="autoComplete"
    endpoint="/equipment"
    item-value="id"
    item-title="name"
    field-name="data"
    single-line
    no-data-text="Start typing to search"
    v-bind="$attrs"
  >
    <template #item="{ props, item }">
      <v-list-item v-bind="omit(props, 'title')">
        <template
          #prepend
        >
          <app-avatar
            color="primary"
            icon="mdi-fan"
            :file-id="item.raw.file_id"
          />
        </template>
        <v-list-item-title>{{ item.raw.name?.toUpperCase() }}</v-list-item-title>
        <v-list-item-subtitle
          v-if="item.raw.supplier"
          v-text="item.raw.supplier.name"
        />
      </v-list-item>
    </template>
    <template #selection="{ item }">
      <v-list-item>
        <template #prepend>
          <app-avatar
            color="primary"
            :file-id="item.raw.file_id"
            icon="mdi-fan"
            size="25"
          />
        </template>
        <v-list-item-title>{{ item.raw.name.toUpperCase() }}</v-list-item-title>
        <v-list-item-subtitle
          v-if="item.raw.supplier"
          v-text="item.raw.supplier.name"
        />
      </v-list-item>
    </template>
  </app-auto-complete>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppAutoComplete from '@/components/AppAutoComplete.vue'
import AppAvatar from '@/components/AppAvatar.vue'
import { omit } from 'lodash'

export default defineComponent({
  components: {
    AppAvatar,
    AppAutoComplete
  },
  inheritAttrs: false,
  methods: {
    omit
  }
})
</script>

<style scoped></style>
