<template>
  <v-bottom-navigation
    class="app-bottom-drawer"
  >
    <v-btn
      size="x-small"
      class="app-bottom-drawer__btn"
      :to="$routeTo(ROUTES.DASHBOARD)"
    >
      <v-icon>md:dashboard</v-icon>
    </v-btn>

    <v-btn
      size="x-small"
      class="app-bottom-drawer__btn"
      :to="$routeTo(ROUTES.TASK_CENTER)"
    >
      <v-icon>md:build</v-icon>
    </v-btn>

    <v-btn
      size="x-small"
      color="primary"
      variant="elevated"
      class="app-bottom-drawer__btn app-bottom-drawer__btn--add"
      @click="showAddOptions"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn
      size="x-small"
      class="app-bottom-drawer__btn"
      :to="$routeTo(ROUTES.CHECKLISTS)"
    >
      <v-icon>mdi-format-list-checks</v-icon>
    </v-btn>

    <v-btn
      size="x-small"
      class="app-bottom-drawer__btn"
      :to="$routeTo(ROUTES.MOBILE_DRAWER)"
    >
      <v-icon>md:menu</v-icon>
    </v-btn>
  </v-bottom-navigation>

  <vue-bottom-sheet
    ref="bottomSheet"
    @closed="showMore = false"
  >
    <v-list density="compact">
      <v-list-item
        v-for="(item, index) in shortList"
        :key="index"
        :prepend-icon="item.icon"
        @click="goTo(item)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        <v-divider />
      </v-list-item>
      <v-expand-transition>
        <div v-if="showMore">
          <v-list-item
            v-for="(item, index) in longList"
            :key="'long-list-' + index"
            :prepend-icon="item.icon"
            @click="goTo(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-divider />
          </v-list-item>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <template v-if="!showMore && longList.length">
          <v-list-item
            prepend-icon="mdi-dots-horizontal"
            @click="showMore = !showMore"
          >
            <v-list-item-title>Show More</v-list-item-title>
          </v-list-item>
        </template>
      </v-expand-transition>
    </v-list>
  </vue-bottom-sheet>
</template>

<script lang="ts">
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'

interface BottomSheetItems {
  icon: string
  to: string
  title: string
}

export default defineComponent({
  components: { VueBottomSheet },
  data() {
    return {
      showMore: false
    }
  },
  computed: {
    shortList(): BottomSheetItems[] {
      let list: BottomSheetItems[] = [{ icon: 'md:build', to: this.ROUTES.TASK_CENTER_CREATE, title: 'Add Task' }]

      if (this.$route.params.storeId) {
        list = list.concat([
          { icon: 'md:content_paste', to: this.ROUTES.MAINTENANCE_QUEUE, title: 'Conduct Maintenance' },
          { icon: 'mdi-car', to: this.ROUTES.INCIDENT_REPORT_SUBMISSION, title: 'File Incident Report' }
        ])
      }

      return list
    },
    longList(): BottomSheetItems[] {
      const list: BottomSheetItems[] = []

      if (this.$store.activeStore) {
        list.push({ icon: 'md:settings', to: this.ROUTES.STORE_SETTINGS_EQUIPMENT, title: 'Manage Equipment' })
        list.push({
          icon: 'mdi-format-list-checks',
          to: this.ROUTES.STORE_SETTINGS_CHECKLISTS_ADD,
          title: 'Add Checklist'
        })
      }

      return list
    },
    ...mapState(useAppStore, ['plusButtonAction'])
  },
  methods: {
    showAddOptions() {
      if (this.plusButtonAction) {
        if (typeof this.plusButtonAction === 'function') {
          this.plusButtonAction()
          return
        }
        this.$router.push(this.plusButtonAction)
        return
      }
      const bottomSheet = this.$refs.bottomSheet as typeof VueBottomSheet
      bottomSheet.open()
      bottomSheet.contentH = 'auto'
    },
    goTo(item: BottomSheetItems) {
      const bottomSheet = this.$refs.bottomSheet as typeof VueBottomSheet
      this.$router.push(this.$routeTo(item.to))
      bottomSheet.close()
    }
  }
})
</script>

<style lang="scss" scoped>
.app-bottom-drawer {
  height: max(56px, calc(40px + var(--v-safe-area-bottom))) !important;
  padding-bottom: calc(var(--v-safe-area-bottom) - 10px) !important;
  justify-content: flex-end;
  $self: &;

  &__btn {
    :deep(.v-btn__content) {
      padding-bottom: calc(var(--v-safe-area-bottom) - 10px) !important;
    }

    #{$self} &--add.v-btn {
      :deep(.v-btn__content) {
        padding-bottom: 0 !important;
      }

      background-color: rgb(var(--v-theme-primary));
      margin: auto;
      border-radius: 100% !important;
      height: 40px !important;
      min-width: 35px !important;

      color: white !important;
    }
  }
}

:deep(.bottom-sheet) {
  hr {
    margin-bottom: 0;
  }

  :deep(.bottom-sheet__pan) {
    height: 18px;
    padding-bottom: 0;
  }
}
</style>
