import { defineStore } from 'pinia'
import axios from 'axios'
import type { AxiosResponse } from '@/plugins/axios'
import { Capacitor } from '@capacitor/core'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { useStoreStore } from '@/stores/store'

export interface LoginForm {
  email: string
  password: string
}

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: null as App.Models.User | null
    }
  },
  actions: {
    async login({ email, password }: LoginForm) {
      const response: AxiosResponse<{ access_token: string }> = await axios
        .post('/auth', {
          email,
          password
        })
        .catch((e) => e)

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token)
        await this.loadUser()
      }

      return response
    },
    async logout() {
      await axios.delete('/auth').catch((e) => e)
      this.user = null
      useStoreStore().stores = []
      useStoreStore().stores_loaded = false
      localStorage.removeItem('token')
    },
    async loadUser(force: boolean = false) {
      if (this.user && !force) {
        return
      }
      const { data: { user } }: AxiosResponse<{ user: App.Models.AuthUser }> = await axios.get('/auth').catch((e) => e)
      this.user = user
      if (this.user) {
        if (Capacitor.isNativePlatform()) {
          CapacitorUpdater.setCustomId({ customId: this.user.id + ' - ' + this.user.email }).then()
        }
      }
    },
    async impersonate(userId: number) {
      const response: AxiosResponse<{ access_token: string }> = await axios
        .post('/auth/impersonate', {
          user_id: userId
        })

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token)
        await this.loadUser(true)
      }

      return response
    },
    async stopImpersonating() {
      const response: AxiosResponse<{ access_token: string }> = await axios
        .delete('/auth/stop-impersonating')
        .catch((e) => e)

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token)
        await this.loadUser(true)
      }

      return response
    }
  }
})
