import axios, { type AxiosResponse } from 'axios'
import type { App } from 'vue'

export function fileUrl(id: number, size: string = 'thumbnail'): string {
  return `${import.meta.env.VITE_API_BASE_URL}/files/${id}/${size}`
}

export function downloadFile(id: number, size: string = 'thumbnail'): Promise<AxiosResponse> {
  return axios.download(fileUrl(id, size))
}

export default {
  install(app: App) {
    app.config.globalProperties.$file = {
      url: fileUrl,
      download: downloadFile
    }
  }
}
