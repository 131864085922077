<template>
  <v-app>
    <app-messages />
    <v-main>
      <v-slide-x-reverse-transition mode="out-in">
        <span class="transition-wrapper">
          <slot />
        </span>
      </v-slide-x-reverse-transition>
    </v-main>
    <update-dialog />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UpdateDialog from '@/components/UpdateDialog.vue'
import AppMessages from '@/components/AppMessages.vue'

export default defineComponent({
  components: { AppMessages, UpdateDialog }
})
</script>

<style scoped></style>
