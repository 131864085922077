<template>
  <v-app id="carwashai">
    <app-messages />
    <conduct-maintenance-toolbar />
    <conduct-maintenance-navigation />
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ConductMaintenanceNavigation from '@/components/ConductMaintenanceNavigation.vue'
import ConductMaintenanceToolbar from '@/components/ConductMaintenanceToolbar.vue'
import AppMessages from '@/components/AppMessages.vue'

export default defineComponent({
  components: { ConductMaintenanceNavigation, ConductMaintenanceToolbar, AppMessages }
})
</script>

<style scoped></style>
