import { type App } from 'vue'
import routeTo from '@/plugins/routeTo'
import file from '@/plugins/file'
import errorHandler from '@/plugins/errorHandler'
import axios from '@/plugins/axios'
import auth from '@/plugins/auth'
import { ROUTES } from '@/router/paths'
import { createHead, VueHeadMixin } from '@unhead/vue'
import { NativeBiometric } from '@capgo/capacitor-native-biometric'
import { Capacitor } from '@capacitor/core'
import Pusher from 'pusher-js'
import push from '@/plugins/push'
import store from '@/plugins/store'
import Confirm from '@/plugins/confirm'

const oldNative = Capacitor.isNativePlatform
Capacitor.isNativePlatform = () => {
  return oldNative() || import.meta.env.VITE_APP_PLATFORM === 'native'
}

export function usePlugins(app: App) {
  // Global Mixins
  app.mixin(VueHeadMixin)

  // Plugins
  app.use(axios)
  app.use(auth)
  app.use(createHead())
  app.use(store)
  app.use(file)
  app.use(Confirm)

  app.config.globalProperties.$error = errorHandler
  app.config.globalProperties.$routeTo = routeTo
  app.config.globalProperties.ROUTES = ROUTES
  app.config.globalProperties.$capacitor = Capacitor
  app.config.globalProperties.$nativeBiometric = NativeBiometric
  app.config.globalProperties.$pusher = new Pusher(import.meta.env.VITE_PUSHER_API_KEY, {
    cluster: 'us2',
    encrypted: true,
    authEndpoint: '/notifications/auth'
  })
  app.config.globalProperties.$push = push
}
