<template>
  <app-auto-complete
    :endpoint="'/users' + (storeId ? '/' + storeId : '') + '?excludeOwners=' + excludeOwners"
    :prepend-inner-icon="noIcon ? null : 'mdi-account'"
    item-value="id"
    :field-name="'users'"
    v-bind="$attrs"
  >
    <template #item="{ item: user }">
      <v-list-item>
        <template #prepend>
          <v-avatar class="ma-0">
            <v-img
              v-if="user.file_id"
              :src="$file.url(user.file_id)"
            />
            <v-icon
              v-else
              color="black"
              v-text="'mdi-account'"
            />
          </v-avatar>
        </template>
        <v-list-item-title>{{ user.full_name }}</v-list-item-title>
      </v-list-item>
    </template>
    <template #selection="{ item: user }">
      <v-avatar
        v-if="user.file_id"
        size="22"
      >
        <v-img
          :src="$file.url(user.file_id)"
        />
      </v-avatar>
      {{ user.full_name }}
    </template>
  </app-auto-complete>
</template>

<script lang="ts">
import AppAutoComplete from '@/components/AppAutoComplete.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AppAutoComplete },
  inheritAttrs: false,
  props: {
    storeId: {
      type: String,
      required: false,
      default: undefined
    },
    excludeOwners: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
