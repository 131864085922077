<template>
  <app-auto-complete
    ref="select"
    v-model="filterPreset"
    field-name="filters"
    item-value="id"
    return-object
    item-title="name"
    :cache-items="false"
    endpoint="/preset-task-filters"
    label="Preset Filters"
    clearable
    :additional-items="additionalItems"
  >
    <template #item="{ item }">
      <v-list-item-title>{{ item.name }}</v-list-item-title>

      <v-list-item-action>
        <v-btn
          v-if="item.deletable !== false"
          icon
          :loading="deleting === item.id"
          :disabled="!!deleting"
          @click.prevent.stop="deletePreset(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
    <template #selection="{ item }">
      <v-list-item-title>{{ item.name }}</v-list-item-title>

      <v-list-item-action>
        <v-btn
          v-if="item.deletable !== false"
          icon
          :loading="deleting === item.id"
          :disabled="!!deleting"
          @click.prevent.stop="deletePreset(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </app-auto-complete>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppAutoComplete from '@/components/AppAutoComplete.vue'
import { useTaskStore } from '@/stores/task'

export default defineComponent({
  components: { AppAutoComplete },
  data() {
    return {
      filterPreset: null as null | App.Models.PresetTaskFilter,
      deleting: null as null | number
    }
  },
  computed: {
    additionalItems() {
      return [
        {
          name: 'None',
          id: 'none',
          deletable: false
        },
        {
          name: 'My Task',
          id: 'my-task',
          deletable: false,
          filters: {
            assigned_to_ids: [this.$auth.user.id],
            statuses: [
              'Monitoring',
              'Waiting',
              'Scheduled',
              'Assigned',
              'In Progress',
              'Pending Assignment'
            ]
          }
        }
      ]
    }
  },
  watch: {
    filterPreset: {
      handler(val) {
        if (val.id) {
          useTaskStore().setFilters(val.filters)
        } else {
          useTaskStore().clearFilters()
        }
      }
    }
  },
  methods: {
    async deletePreset(id: number) {
      if (!await this.$confirm('Are you sure', 'Are you sure you want to delete this preset?', 'error')) return
      this.deleting = id
      const {
        data: { message, errors },
        status
      } = await this.$http.delete(`/preset-task-filters/${id}`, {}).catch(e => e)
      this.deleting = null
      if (this.$error(status, message, errors)) return
      if (this.filterPreset?.id === id) {
        this.filterPreset = null
      }
      this.reset()
    },
    reset() {
      (this.$refs.select as any).reset()
    }
  }
})
</script>

<style scoped>

</style>
