<template>
  <app-dialog
    fullscreen
  >
    <template #activator="{props}">
      <v-hover v-slot="{ props: hoverProps, isHovering }">
        <v-img
          :src="src ? src : $file.url(fileId, resolution)"
          :lazy-src="src ? src : $file.url(fileId, 'thumbnail')"
          v-bind="mergeProps($attrs, props, hoverProps)"
          :position="position"
          style="cursor: pointer; width: 100%"
        >
          <slot :is-hovering="isHovering" />
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="black-lighten-5"
              />
            </v-row>
          </template>
        </v-img>
      </v-hover>
    </template>
    <template #default="{isActive}">
      <v-card
        color="#130f0f"
        class="flex flex-column justify-center"
      >
        <v-card-title>
          <v-spacer />
          <v-icon
            color="white"
            @click="isActive.value = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          style="height: calc(100vh - 75px); display: flex; justify-content: center; flex-direction: column;"
        >
          <v-img
            :src="src ? src : $file.url(fileId, 'original')"
            :lazy-src="src ? src : $file.url(fileId, 'thumbnail')"
            max-height="calc(100vh - 75px)"
          >
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="black-lighten-5"
                />
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
    </template>
  </app-dialog>
</template>

<script lang="ts">
import { defineComponent, mergeProps } from 'vue'
import AppDialog from '@/components/AppDialog.vue'

export default defineComponent({
  methods: { mergeProps },
  components: { AppDialog },
  inheritAttrs: false,
  props: {
    fileId: {
      type: Number,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    resolution: {
      type: String,
      default: 'thumbnail'
    },
    position: {
      type: String,
      default: 'top center'
    }
  }
})
</script>

<style scoped>

</style>
