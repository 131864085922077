import { defineStore } from 'pinia'
import router from '@/router'
import type { RouteLocationRaw } from 'vue-router'

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      messages: [] as { type: 'Success' | 'Error', message: string }[],
      drawer: null as boolean | null,
      responsive: false as boolean,
      plusButtonAction: null as (() => void) | null | RouteLocationRaw,
      tablesShouldRefresh: false as boolean
    }
  },
  actions: {
    addError(error: string, timeout: number = 5000) {
      this.messages.push({ type: 'Error', message: error })
      setTimeout(() => {
        this.messages.splice(0, 1)
      }, timeout)
    },
    addSuccess(message: string, timeout: number = 5000) {
      this.messages.push({ type: 'Success', message })
      if (timeout > 0) {
        setTimeout(() => {
          this.messages.splice(0, 1)
        }, timeout)
      }
    }
  }
})

router.beforeEach((_to, _from, next) => {
  useAppStore().plusButtonAction = null
  next()
})
